import React from 'react';
import NormalizedNumber from './normalizedNumber.jsx'
import NormalizedDateTime from './normalizedDateTime.jsx'

class UsageVoice extends React.Component{
    
    constructor(props, context) {
        super(props, context);

        this.state = {

        }
    }

    render(){
        if (this.props && this.props.Calls) {
            return (
                <div>
                    <div className="tableResponsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Date &amp; Time</th>
                                    <th>Duration (minutes)</th>
                                    <th>Type</th>
                                    <th>Result</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.props.Calls.map(function (call, index) {
                                        if (call) {
                                            return <tr key={index}><td><NormalizedNumber number={call.CallToNumber} /></td>
                                                <td><NormalizedDateTime dateTime={call.StartTime} showTime={true} /></td>
                                                <td>{call.Duration}</td>
                                                <td>{call.Type}</td><td>{call.Result}</td></tr>
                                        }
                                    })
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }
}

export default UsageVoice;