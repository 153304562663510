import React from 'react';

class NumbersLoading extends React.Component{

    render(){
        return(
        <div>
            <div class="ph-item margin-top-15 no-br">
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                <div class="ph-col-12 no-br margin-5-top">
                    <div class="ph-row">
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-4 big"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                        <div class="ph-col-1 empty"></div>
                    </div>
                </div>
                
            </div>
           
        </div>)
    }
}

export default NumbersLoading;