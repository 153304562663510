import React from 'react';
import logo from '../img/groove_ip_logo_v4_blue.png';

class MarketingNavBar extends React.Component{

    render(){
        return(
            <div>
                <nav className="navbar navbar-expand-lg navbar-top">
                    <div className="container">
                        <a className="navbar-brand" href="#"><img src={logo} alt="GrooVe IP" width="167" height="51" /></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
        
                       <div id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto"></ul>
                            <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="./faq">FAQ</a>
                                    </li>
                                <li><a href="./login" id="home-login-btn" className="btn btn-light">Login</a></li>
                            </ul>
                            <form className="form-inline">
                                
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }   
}

export default MarketingNavBar;