import React from 'react';

class FAQ extends React.Component{

    render(){
        return(
            <div className="container">
                <h1>Frequently Asked Questions</h1>
                <div id="purchase-required-alert" className="alert alert-warning margin-top-15">
                    <h4>GrooVe IP FAQ</h4>
                    <p>
                        <strong>Coming Soon! </strong> We are currently in the process of updating of FAQ.  We appreciate 
                        your patience during this time and apologize for any inconvenience.
                    </p>
                    
                </div>
            </div>
        )
    }
}

export default FAQ;