import React from 'react';


class BalanceHelp extends React.Component{
    
    constructor(props, context) {
        super(props, context);
    }

    render(){
        return(
            <div>
                <h3 className="paragraph-header">Add to Your Balance</h3>
                
                <p>Add to your balance by clicking the "Balance" link in the left navigation window</p>

            </div>
        );
    }
}

export default BalanceHelp;