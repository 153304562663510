var cookieDate = new Date();
var cookieExpiryMinutes = 15;
cookieDate.setTime(cookieDate.getTime() + (cookieExpiryMinutes * 60 * 1000));

var bearerDate = new Date();
var bearerExpiryMinutes = 120;
bearerDate.setTime(cookieDate.getTime() + (cookieExpiryMinutes * 60 * 1000));


export const COOKIE_EXPIRE_TIME = cookieDate;
export const BEARER_EXPIRE_TIME = bearerDate;

export const COOKIE_ID_KEY = 'snrb_cust_id';
export const COOKIE_TOKEN_KEY = 'snrb_token';
export const COOKIE_EMAIL_KEY = 'snrb_email';
export const COOKIE_BEARER_TOKEN = 'btoken';

export const BASE_PORTAL_URL = 'https://portal.snrblabs.com/';
//export const BASE_PORTAL_URL = 'https://reactnodedemo.azurewebsites.net/';
//export const BASE_PORTAL_URL = 'http://localhost:5002/';

const BASE_API_URL = 'https://commercial.snrblabs.com/api/';
//const BASE_API_URL = 'https://snrblabs-dev-sandbox.azurewebsites.net/api/';
//const BASE_API_URL = 'http://staging-commercial.azurewebsites.net/api/';
//const BASE_API_URL = 'http://localhost:54955/api/';

export const REGISTRATION_URL = BASE_PORTAL_URL + 'api/register';
export const REGISTRATION_RESEND_CONFIRMATION_URL = BASE_API_URL + 'Account/VerifyResend';
export const LOGIN_URL = BASE_PORTAL_URL + 'api/login';

export const PASSWORD_RESET_URL = BASE_PORTAL_URL + 'api/password/reset';
export const PASSWORD_CHANGE_URL = BASE_PORTAL_URL + 'api/password/change';

export const VERIFY_URL = BASE_API_URL + 'Account/Verify';
export const VERIFY_RESEND_URL = BASE_API_URL + 'Account/VerifyResend';

export const NUMBER_RESERVE_URL = BASE_PORTAL_URL + 'api/numbers/reserve';

export const PAYMENT_VERIFY_URL = BASE_PORTAL_URL + 'api/payment/verify';

function getFormattedNumberUrl(phoneNumber) {
    return BASE_PORTAL_URL + phoneNumber.replace('+1', '');
}

function getPasswordChangeUrl() {
    return BASE_PORTAL_URL + 'password/changecustomer';
}

function getPasswordResetUrl() {
    return BASE_PORTAL_URL + 'password/resetcustomer';
}

export function getAccountInfoUrl(customerId, token) {
    return BASE_API_URL + 'portal/accountinfo/' + customerId + '/token/' + token;
}

export function getBalanceUrl(customerId, token) {
    return BASE_API_URL + 'credits/balance/' + customerId + '/token/' + token;
}

export function getNumbersSearchUrl(customerId, areaCode, token) {
    return BASE_API_URL + 'numbers/list/' + customerId + '/areaCode/' + areaCode + '/requestId/requestId/hash/hash/token/' + token;
}

export function getNumbersInventoryUrl(customerId, page, records, query, token) {
    return BASE_API_URL + 'numbers/inventoryportal/' + customerId + '/page/' + page + '/quantity/' + records + '/query/' + query +'/token/' + token;
}

export function getUserPasswordResetUr(customerId, token, number){
    return BASE_API_URL + 'password/resetnumber/' + customerId + '/token/' + token + '/number/' + number;
}

export function getNumberDeleteUrl(customerId, number){
    return BASE_PORTAL_URL + 'api/numbers/delete/' + customerId + '/number/' + number;
}

export function getCallHistoryUrl(custId, token, nuid) {
    return BASE_API_URL + 'call/historyportal/' +
            custId + '/token/' + token + '/nuid/' + nuid;
}

export function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}

export function paginate(array, page_size, page_number) {
     --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
}


export function filterSmsByStartDate(data, startDate) {
    
    if (startDate) {

        var date = new Date(startDate);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(1);

        var filteredData = new Array();

        for (var i = 0; i < data.SmsRecords.length; i++) {
            if (new Date(data.SmsRecords[i].StartTime) >= date) {
                filteredData.push(data.SmsRecords[i]);
            }
        }

        return filteredData;

    } else {
        return data.SmsRecords;
    }
}

export function filterSmsByEndDate(data, endDate) {
  
    if (endDate) {

        var filteredData = new Array();

        var date = new Date(endDate);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(1);

        for (var i = 0; i < data.SmsRecords.length; i++) {
            if (new Date(data.SmsRecords[i].StartTime) >= date) {
                filteredData.push(data.SmsRecords[i]);
            }
        }

        return filteredData;

    } else {
        return data.SmsRecords;
    }
}



export function filterCallsByStartDate(data, startDate) {
    
    if (startDate) {

        var date = new Date(startDate);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(1);

        var filteredData = new Array();

        for (var i = 0; i < data.Records.length; i++) {
            if (new Date(data.Records[i].StartTime) >= date) {
                filteredData.push(data.Records[i]);
            }
        }

        return filteredData;

    } else {
        return data.Records;
    }
}

export function filterCallsByEndDate(data, endDate) {
    
    if (endDate) {

        var date = new Date(endDate);
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);

        var filteredData = new Array();

        for (var i = 0; i < data.Records.length; i++) {
            if (new Date(data.Records[i].StartTime) <= date) {
                filteredData.push(data.Records[i]);
            }
        }

        return filteredData;

    } else {
        return data.Records;
    }
}

export function filterCallsByInOutStatus(data, inOutFilter) {

    var filteredData = new Array();
    var filterType;

    if (inOutFilter === 'Incoming' || inOutFilter === 'In') {
        filterType = 'Incoming';
    } else if (inOutFilter === 'Out') {
        filterType = 'Outgoing';
    }

    if (filterType) {

        for (var i = 0; i < data.length; i++) {
            if (data[i].Type === filterType) {
                filteredData.push(data[i]);
            }
        }

        return filteredData;

    } else {
        return data;
    }

}

export var dateSortAsc = function (date1Str, date2Srt) {
    var date1 = new Date(date1Str);
    var date2 = new Date(date2Srt);
    if (date1 > date2) return 1;
    if (date1 < date2) return -1;
    return 0;
};

export var dateSortDesc = function (date1Str, date2Srt) {

    var date1 = new Date(date1Str);
    var date2 = new Date(date2Srt);

    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
};
