import React from 'react'
import {Link} from "react-router-dom";

class Pricing extends React.Component{

    constructor(props, context){
        super(props, context);
    }

    render(){

        var backNav;
        if(this.props.navstack && this.props.navstack == 'register'){
            backNav = (
                <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='./register'>Register</Link></li>
                        <li className="breadcrumb-item active">Pricing</li>
                </ol>
            );
        }

        return(
            <div>
                


                {this.props.navstack && (
                    
                    <div>
                        {backNav}
                    </div>
                )}

                <h2>Price List</h2>
                    <div>
                        <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className="nav-item"><a className="nav-link active" href="#numbers" aria-controls="voice" role="tab" data-toggle="tab">Numbers</a></li>
                            <li role="presentation" className="nav-item"><a className="nav-link" href="#minutes" aria-controls="minutes" role="tab" data-toggle="tab">Minutes</a></li>
                            <li role="presentation" className="nav-item"><a className="nav-link" href="#sms" aria-controls="sms" role="tab" data-toggle="tab">SMS</a></li>
                            <li role="presentation" className="nav-item"><a className="nav-link" href="#mms" aria-controls="mms" role="tab" data-toggle="tab">MMS</a></li>
                            <li role="presentation" className="nav-item"><a className="nav-link" href="#short" aria-controls="sms" role="tab" data-toggle="tab">Short-Code</a></li>
                        </ul>
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active" id="numbers">
                                <div className="card">
                                    <div className="card-body">
                                        
                                        <h3 className="card-title">Phone Numbers</h3>
                                        
                                        <p>Phone numbers can be reserved for a one-time charge of $1.00. This includes the recurring charge for the first month.</p>
                                        <p>From second month onwards, the monthly charge for each number you have reserved is $0.75.</p>

                                        <p><strong>Notes</strong></p>
                                        <ul>
                                            <li>These are VoIP based numbers and DO NOT include 911 service</li>
                                            <li>All charges are inclusive of all taxes</li>
                                            <li>Volume discounts are available to postpaid customers only</li>
                                        </ul>

                                        <p>
                                            Volume discounts are available to customers who have more than 100
                                            numbers and/or customers who are postpaid.  If you have more than 100 numbers
                                            or would like to convert your account to a postpaid account please <a href="mailto:snrb.labs@gmail.com">contact us</a> for details.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane" id="minutes">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Minutes</h3>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Incoming</th>
                                                    <th>Outgoing</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>$0.005 per minute</td>
                                                    <td>$0.0125 per minute</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ul>
                                            <li>All charges are inclusive of all taxes</li>
                                            <li>
                                                Volume discounts are available to postpaid customers. <a href="mailto:snrb.labs@gmail.com">Contact us</a> for more details.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane" id="sms">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">SMS</h3>
                                        <p>
                                            <strong>
                                                    SMS is billed per message segment. A message that is over 160 characters, or 70 characters if there are emojis and/or foreign characters, are billed separately as individual segments. From a cost perspective, it is calculated by multiplying the rate by the number of segments.
                                            </strong>
                                        </p>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Incoming</th>
                                                    <th>Outgoing</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>$0.005 per message segment</td>
                                                    <td>$0.007 per message segment</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ul>
                                            <li>All charges are inclusive of all taxes</li>
                                            <li>
                                                Volume discounts are available to postpaid customers. <a href="mailto:snrb.labs@gmail.com">Contact us</a> for more details.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane" id="mms">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">MMS</h3>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Incoming</th>
                                                    <th>Outgoing</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>$0.01 per message</td>
                                                    <td>$0.02 per message</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ul>
                                            <li>All charges are inclusive of all taxes</li>
                                            <li>
                                                Volume discounts are available to postpaid customers. <a href="mailto:snrb.labs@gmail.com">Contact us</a> for more details.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane" id="short">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Short Code SMS</h3>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Incoming</th>
                                                    <th>Outgoing</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>$0.02 per message</td>
                                                    <td>$0.02 per message</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <ul>
                                            <li>All charges are inclusive of all taxes</li>
                                            <li>
                                                Volume discounts are available to postpaid customers. <a href="mailto:snrb.labs@gmail.com">Contact us</a> for more details.
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane" id="mms">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">MMS (Picture Messaging)</h3>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Incoming</th>
                                                    <th>Outgoing</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>$0.01 per message</td>
                                                    <td>$0.02 per message</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <ul>
                                            <li>All charges are inclusive of all taxes</li>
                                            <li>
                                                Volume discounts are available to postpaid customers. <a href="mailto:snrb.labs@gmail.com">Contact us</a> for more details.
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default Pricing;