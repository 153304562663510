import React from 'react';
import axios from 'axios';
import * as constants from '../utils/constants.js';
import progressSpinner from '../img/rolling.gif';
import BootstrapAlert from './bootstrapAlert.jsx';
import Cookies from 'universal-cookie';

class RegistrationConfirmation extends React.Component {

    constructor(props, context){
        super(props, context);
        this.state = {
            isVerifying : true,
            verificationResent : false,
            isVerifiedFailed : false
        }
    }

    async componentDidMount(){
        const cookies = new Cookies();
        var self = this;
        var email = this.props.email;
        var code = this.props.verificationCode;
        axios.post(constants.VERIFY_URL, {   
            Email: email,
            Code: code
        }).then((res) => {
            
            cookies.set(constants.COOKIE_EMAIL_KEY, email, { expires: constants.COOKIE_EXPIRE_TIME });
            cookies.set(constants.COOKIE_TOKEN_KEY, res.data.Token, { expires: constants.COOKIE_EXPIRE_TIME });
            cookies.set(constants.COOKIE_ID_KEY, res.data.CustomerId, { expires: constants.COOKIE_EXPIRE_TIME });

            var msg = {
                header: 'Validation Succesful',
                description: 'You will now be logged in to your account.',
                alertType: 'success',
                isDismissible: false
            }

            var delayInMilliseconds = 1000; //1 seconds
            var self = this;
            setTimeout(function() {
                self.setState({
                    resultMessage : msg,
                    isVerifying: false,
                    isSuccess: true,
                    isVerifiedFailed: false
                });

                window.location.href = `../portal/account?tk=${res.data.Token}`;
            }, delayInMilliseconds);

        }).catch((error => {
            
            console.log(error);
            var msg = {
                description: 'Sorry, we could not verify your account. Please resend the account validation link by clicking the button on this page.',
                alertType: 'danger',
                isDismissible: false
            }

            this.setState({
                resultMessage : msg,
                isVerifying: false,
                isSuccess: false,
                isVerifiedFailed: true
            });
        }));               
    }

    verficationFailedResend(email, verificationCode){
        
        var self = this;
        
        axios.post(constants.REGISTRATION_RESEND_CONFIRMATION_URL, {   
            Email: email,
            Code: verificationCode
        }).then((response) => {
            
            var msg = {
                description: 'A new verification link has been sent to ' + self.props.email + '',
                alertType: 'success',
                isDismissible: false
              }
            
            self.setState({resultMessage : msg, verificationResent: true});
        }).catch((error => {
            
            var msg = {
                description: 'Verification link resend failed, please try again later.',
                alertType: 'danger',
                isDismissible: true
              }
            
            self.setState({resultMessage : msg, verificationResent: true});
        }));
    }

    render(){
        
        var errorHeader = '';
        if(this.state.verificationResent){
            errorHeader = 'Verification Sent';
        }else if(this.state.isVerifiedFailed){
            errorHeader = 'Verification Failed';
        }

        if(this.state.isVerifying){
            return(
                <div>
                    <h1>Completing Verification</h1>
                    <img className="center-horizontal" src={progressSpinner} height="30" width="30" alt="loading" />
                </div>               
            );
        }else if(this.state.isVerifiedFailed || this.state.verificationResent){
            return(
                <div>
                    <h1>{errorHeader}</h1>
                    <div className="card">
                        <div className="card-body">
                            {<BootstrapAlert msg={this.state.resultMessage}/>}
                            <div className="row">
                                <div className="col-md-4 col-md-offset-3">
                                    <button className='btn btn-primary margin-top-15' type="button" onClick={ () => this.verficationFailedResend(this.props.email, this.props.verificationCode)}>Resend Verification</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
            );      
        }else {
            return(
                <div>
                    <h1>Account Verified!</h1>
                    <div className="card">
                        <div className="card-body">
                            {<BootstrapAlert msg={this.state.resultMessage}/>}
                        </div>
                    </div>
                </div>
            );
        }    
    }
}

export default RegistrationConfirmation;