import React from 'react';
import Moment from 'moment'

class NormalizedDateTime extends React.Component{
    
    constructor(props) {
        super(props);
    }

    render () {
        if (this.props.dateTime) {
            var date = Moment(this.props.dateTime);
            var day = date.date();
            var month = date.get('month');
            month += 1;
            var year = date.get('year');
            var dateTime = month + '/' + day + '/' + year;

            var hour = date.get('hour');
            var minute = date.get('minute');

            var minuteStr;

            if (minute < 10) {
                minuteStr = '0' + minute;
            } else {
                minuteStr = minute;
            }

            var second = date.get('second');

            var secondStr;

            if (second < 10) {
                secondStr = '0' + second;
            } else {
                secondStr = second;
            }


            var time = hour + ':' + minuteStr + ':' + secondStr;

            if (this.props.showTime) {
                return (<span>{dateTime} {time}</span>);
            } else {
                return (<span>{dateTime}</span>);                    
            }

        } else {
            return (<span></span>);
        }
    }
}

export default NormalizedDateTime;