import React from 'react';
import '../css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

{/*
    required fields: message, alertType
    optional fields: header, footer, isDismissable (defaults to false)

    alertType supports bootstrap 4 alert values: primary, secondary, success, danger, warning, info, light, dark
*/}

class BootstrapAlert extends React.Component{
    constructor(props, context) {
        super(props, context);
    }
      
    render() {
   
        var message = '';
        var alertClass = 'alert';
        var closeButtonHtml = '';

        if(this.props.msg){
            message = this.props.msg;
            
            if(message.alertType){
                alertClass = `${alertClass} alert-${message.alertType}` 
            }

            if(message.isDismissible){
                var closeButtonHtml = <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>;
            }

        }

        if(message.header && message.footer){
            return(
                <div>
                    <div className={message ? alertClass : 'hidden'}>
                        {closeButtonHtml}
                        <h4 className="alert-heading">{message.header}</h4>
                        {message.description}
                        <hr/>
                        {message.footer}
                    </div>
    
                </div>
            );
        } else if(message.header){
            return(

                <div>
                    <div className={message ? alertClass : 'hidden'}>
                        <h4 className="alert-heading">{message.header}</h4>
                        {message.description}
                        {closeButtonHtml}
                    </div>
    
                </div>
            );
        } else if(message.footer){
            return(

                <div>
                    <div className={message ? alertClass : 'hidden'}>
                        {message.description}
                        {closeButtonHtml}
                        <hr/>
                        {message.footer}
                    </div>
    
                </div>
            );
        } else {
            return(

            

                <div>
                    <div className={alertClass}>
                        {message.description}
                    </div>
                </div>
            );
        }

        
    }

}

export default BootstrapAlert;

