import React from 'react';
import Menu from 'react-burger-menu/lib/menus/slide'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import '../css/left-nav.css';

//Implementation based on https://github.com/negomi/react-burger-menu
class LeftNav extends React.Component{
    
    constructor(props, context) {
        super(props, context);
        const isMobile = window.innerWidth <= 600;
        
        this.state = {
            isMobile: isMobile
        }
    }

    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
    }

    closeMenu(url) {
        this.setState({menuOpen: false});
        window.location.href = url;
    }

    render(){
      
        var isHighRes = false;
        const isHiResQuery = "(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
   
        if(matchMedia(isHiResQuery)){
            isHighRes = true;
        }
        var leftNavItems = [];

        if(this.props.navConfig.leftMenuItems){
           
            leftNavItems = this.props.navConfig.leftMenuItems.map((n, i) => {
                if(this.state.isMobile){
                    return <li className="menu-item" key={i}><a onClick={() => this.closeMenu(n.url)} href={n.url}>{n.label}</a></li>;  
                 }else {
                    return <Link id={n.label} className="menu-item" to={n.url} key={i}>{n.label}</Link>
                 }
              })
              
            if(this.state.isMobile){
                return (
                    <div className="left-nav-container">
                        <Menu  width={ '60%' } isOpen={ false } disableAutoFocus pageWrapId={ "page-wrap" } burgerButtonClassName={ "burger-button" } noOverlay>
                            {leftNavItems}
                        </Menu>
                    </div>  
                );
            }else {
                return (
                    <div className="left-nav-container">
                        <Menu  width={ '10%' } isOpen={ true } disableAutoFocus pageWrapId={ "page-wrap" } burgerButtonClassName={ "burger-button" }   noOverlay>
                            {leftNavItems}
                        </Menu>
                    </div>  
                );
            }
            
        }else {
            return (
                <div></div>  
            );
        }
    }
}

export default LeftNav;