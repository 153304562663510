import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Cookies from 'universal-cookie';

class NotFound extends React.Component{

    constructor(props, context) {
        super(props, context);
        
        const cookies = new Cookies();
        var token = cookies.get('snrb_token');
        var custId = cookies.get('snrb_cust_id');
      
        let isAuthenticated = false;
        if (token && custId) {
            if(token !== "null" && custId !== "null"){
                isAuthenticated = true;  
            }
            
        } 

        this.state = {
            location : this.props.location,
            isAuthenticated: isAuthenticated
        }
    }

    render(){

        let locationMessage;
        if(this.state.location){
            locationMessage = <h3>404! - Page <code>{this.state.location}</code> not found</h3>;    
        }else {
            locationMessage = '';
        }

        let navLinks;
        if(this.state.isAuthenticated){
            navLinks = (
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/register">Register</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/login">Login</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/portal/account">Account Overview</Link>
                    </li>
                </ul>
            )
        }else{
            navLinks = (
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/register">Register</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/login">Login</Link>
                    </li>
                </ul>
            )
        }

        return(
            <div id="not-found" className="container">
                <div className="content-section">
                    <h3>{locationMessage}</h3>
                    <p>We're sorry but the page you are looking for could not be found</p>
                </div>

                <div className="content-section">
                    <h4>There are several reasons this might have happened:</h4>
                    <ul className="no-matchlist">
                        <li>This page does not exist</li>
                        <li>The page is out-dated has has been removed</li>
                        <li>The address (url) is not correct</li>
                    </ul>
                </div>

                <div className="content-section">
                    <h4>Let's try to get you back on track</h4>
                    {navLinks}
                    
                </div>
            </div>
        );
    }
}

export default NotFound;