import React from 'react';
import itunesLogo from '../img/itunes_app_store.png';
import amazonMarketLogo from '../img/AmazonMarketDownload.png';
import googlePlayLogo from '../img/google-play-badge.png';

class MobileDeeplink extends React.Component {

    render(){

        var mobileOs;

        if(navigator.appVersion.indexOf('iPhone')!=-1) mobileOs = 'ios';
        if(navigator.appVersion.indexOf('Android')!=-1) mobileOs = "android";
        
        var appStoreLinks;
        if(mobileOs === "ios"){
            appStoreLinks = (
                <div>
                    <h5>Download from the iTunes App Store</h5>
                    <div className="row">

                        <div className="col-md-12">

                            <a href='https://itunes.apple.com/us/app/groove-ip/id1291925470?ls=1&mt=8'>
                                <img width="185" height="65" className="store-logo" src={itunesLogo} alt="iTunes App Store" />
                            </a>

                        </div>

                    </div>
                </div>
            );
        }else if(mobileOs === "android"){
            appStoreLinks = (
                <div>
                    <h5>Download from Google Play</h5>
                    <div className="row">
                        <div className="col-md-12">
                            <a href='https://play.google.com/store/apps/details?id=com.snrblabs.grooveip&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <img alt='Get it on Google Play' className="store-logo" width="185" height="65" src={googlePlayLogo} />
                            </a>
                        </div>
                    </div>

                    <h5>Download from the Amazon Store</h5>
                    <div className="row">
                        <div className="col-md-12">
                            <a href="https://www.amazon.com/GrooVe-IP-VoIP-Calls-Text/dp/B007HA7A18/">
                                <img  width="185" height="75" className="store-logo"  src={amazonMarketLogo} alt="download from the android market" />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }else{
            appStoreLinks = (
                <div>We're sorry we don't seem to have a mobile app that supports your device.  
                    Our app is available on the <a href="https://itunes.apple.com/us/app/groove-ip/id1291925470?ls=1&mt=8">iOS app store</a>, 
                    the <a href="https://play.google.com/store/apps/details?id=com.snrblabs.grooveip&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">Android Google Play Store</a>, and 
                    the <a href="https://www.amazon.com/GrooVe-IP-VoIP-Calls-Text/dp/B007HA7A18/">Android Amazon app store</a> only.
                </div>
            );
        }

        
            return(
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">App Download Required</h3>
                                <p>You must download the GrooVe IP app to login.</p>
                                {appStoreLinks}
                            </div>
                        </div>
                    </div>
                </div>   
            </div>);
        
    }
}

export default MobileDeeplink;