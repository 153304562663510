import React from 'react';
import NormalizedNumber from './normalizedNumber.jsx'
import NormalizedDateTime from './normalizedDateTime.jsx'

class UsageSms extends React.Component{
    
    constructor(props, context) {
        super(props, context);

        this.state = {

        }
    }

    render(){
        if (this.props && this.props.Sms) {
            return (
                <div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Date &amp; Time</th>
                                    <th>Type</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>

                                {


                                    this.props.Sms.map(function (sms, index) {
                                        if (sms) {
                                            return <tr key={index}><td><NormalizedNumber number={sms.CallToNumber} /></td><td><NormalizedDateTime dateTime={sms.StartTime} showTime={true} /></td>
                                                <td>{sms.Type}</td><td>{sms.Message}</td></tr>
                                        }
                                    })
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }
}

export default UsageSms;