import React from 'react';
import axios from 'axios';
import * as constants from '../utils/constants.js';
import BootstrapAlert from './bootstrapAlert.jsx'
import RegistrationConfirmation from './registrationConfirmation';

class RegistrationVerification extends React.Component{

    constructor(props, context){
        super(props, context);

        //vc is the query string for verification code

        this.state = {
            email : constants.getParameterByName("email"),
            verificationCode : constants.getParameterByName("vc"),
            verificationResent: false
        }

        this.resend = this.resend.bind(this);
    }

    resend(){

        var self = this;
        self.setState({verificationResent: false});

        axios.post(constants.REGISTRATION_RESEND_CONFIRMATION_URL, {   
            Email: this.state.email,
            Code: this.state.verificationCode
        }).then((response) => {
            var msg = {
                description: 'A new verification link has been sent to ' + this.state.email + '',
                alertType: 'success',
                isDismissible: false
            }
            
            self.setState({resultMessage : msg, verificationResent: true});
        }).catch((error => {
            var msg = {
                description: 'Verification link resend failed, please try again later',
                alertType: 'danger',
                isDismissible: true
              }
            
            self.setState({resultMessage : msg, verificationResent: true});
        }));
    }

    render(){

        if(!this.state.verificationCode){
            return(
                <div>
                    <h1>Account Verification</h1>
                    <div className="card">
                        <div className="card-body">
                            <p>A verification link has been sent to {this.state.email} please verfiy your account to continue.</p>
                            <div className="row">
                                <div className="col-md-4 col-md-offset-3">
                                    <button className='btn btn-primary margin-top-15' type="button" onClick={this.resend}>Resend Verification</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.verificationResent && (
                    <div>
                        <BootstrapAlert msg={this.state.resultMessage}/>
                        <a href="../../login">Return to Login</a>
                    </div>
                    )}
                </div>
            );
        }else {

            var redirctUrl = '../portal/account?vc=' + this.state.verificationCode;

            return(
                <div>
                    <RegistrationConfirmation redirctUrl={redirctUrl} email={this.state.email} verificationCode={this.state.verificationCode} />
                </div>
                
            )
        }

        
    }
}

export default RegistrationVerification;