import React from 'react';
import $ from "jquery";
import '../css/push.css';
import BootstrapAlert from './bootstrapAlert.jsx'
import NormalizedNumber from './normalizedNumber.jsx'
import NormalizedDateTime from './normalizedDateTime.jsx'
import * as constants from '../utils/constants.js';
import Cookies from 'universal-cookie';
import axios from 'axios';

class NumberDetail extends React.Component{
    
    constructor(props) {
        super(props);

        this.state = {
            newPassword: null,
            isMobileDevice: false,
            authMsg: null
        };

        this.resetNumber = this.resetNumber.bind(this);
        this.deleteNumber = this.deleteNumber.bind(this);
    }

    resetNumber(){
        var token = Cookies.get(constants.COOKIE_TOKEN_KEY);
        var custId = Cookies.get(constants.COOKIE_ID_KEY);

        if (!token || !custId) {
            window.location.href = '../login';
            return;
        } else {
            Cookies.set(constants.COOKIE_TOKEN_KEY, token, { expires: constants.COOKIE_EXPIRE_TIME });
            Cookies.set(constants.COOKIE_ID_KEY, custId, { expires: constants.COOKIE_EXPIRE_TIME });
        }

        var self = this;

        var uri = $.encodeURI(constants.getUserPasswordResetUr(custId, token, this.props.currentItem.PhoneNumber));
        
        $.ajax({
            type: 'GET',
            url: uri,
            contentType: 'application/x-www-form-urlencoded',
            dataType: 'json',
            success: function (res) {
                 
                if (res) {
                    self.setState({
                        newPassword: res
                    });
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                var msg = JSON.parse(xhr.responseText).Message;
                if (msg.startsWith('token_')) {
                    Cookies.set(constants.COOKIE_ID_KEY, '', { expires: 0 });
                    Cookies.set(constants.COOKIE_TOKEN_KEY, '', { expires: 0 });
                    Cookies.set(constants.COOKIE_EMAIL_KEY, '', { expires: 0 });
                    window.location.href = '../login?state=' + msg;
                }

                self.setState({ validationError: msg });
            }
        });
    }

    deleteNumber() {
        const cookies = new Cookies();
        var self = this;

        var numberId = this.state.selectedId;
       
        var token = cookies.get(constants.COOKIE_TOKEN_KEY);
        var custId = cookies.get(constants.COOKIE_ID_KEY);

        if (!token || !custId) {
            window.location.href = '../login';
            return;
        } else {
            cookies.set(constants.COOKIE_TOKEN_KEY, token, { expires: constants.COOKIE_EXPIRE_TIME });
            cookies.set(constants.COOKIE_ID_KEY, custId, { expires: constants.COOKIE_EXPIRE_TIME });
        }

        var uri = constants.getNumberDeleteUrl(custId, this.props.currentItem.PhoneNumber);
        axios.delete(uri,{
            headers: {
                'Authorization': cookies.get(constants.COOKIE_BEARER_TOKEN)
            },
            data : {
               token: token,
               email: cookies.get(constants.COOKIE_EMAIL_KEY) 
            }
        })
        .then(response => {
           
            if (response) {
                window.location.href = '../portal/numbers';
            }
        }).catch(error => {
           
            if (error.message.startsWith('token_')) {
                cookies.set(constants.COOKIE_ID_KEY, '', { expires: 0 });
                cookies.set(constants.COOKIE_TOKEN_KEY, '', { expires: 0 });
                cookies.set(constants.COOKIE_EMAIL_KEY, '', { expires: 0 });
                window.location.href = '../Account/Login?state=' + error.message;
            } else {
                var errorMsg = {
                    description: error.message,
                    alertType: 'danger',
                    isDismissible: true
                }

                this.setState({
                    authMsg: errorMsg
                });  
            }
        });
    }

    render() {

        let deeplinkHttpsSchema = '';
        let deeplinkSnrbSchema = '';
        const isMobileOrTablet = window.innerWidth < 769;

        if(this.props && this.props.currentItem){

            var userName;
            if (this.props.currentItem.UserName) {
                userName = this.props.currentItem.UserName;
            } else {
                userName = this.props.currentItem.Email;
            }

            if(isMobileOrTablet){
                deeplinkHttpsSchema = `https://portal.snrblabs.com/portal/mobilesignin?uid=${userName}&pw=${this.props.currentItem.Password}`;
                deeplinkSnrbSchema = `snrb://portal.snrblabs.com/portal/mobilesignin?uid=${userName}&pw=${this.props.currentItem.Password}`;
            }

            var sipId = '';
            if (this.props.currentItem.SipSecondary) {
                if (this.props.currentItem.SipSecondary.UserName) {
                    sipId = this.props.currentItem.SipSecondary.UserName;
                } else {
                    sipId = this.props.currentItem.SipSecondary.SipId;
                }
            }

            return(
                <div className="detailPane">
                    <div className="panel-heading">
                        <h3 className="panel-title">{<NormalizedNumber number={this.props.currentItem.PhoneNumber} />}</h3>
                    </div>
                    
                    <div className="panel-body">

                        <div className="margin-left-15">

                                
                                <div>
                                    <div className="row">
                                       <button type="button" data-toggle="modal" data-target="#delete-confirm-modal" className="btn btn-sm btn-danger">Delete Number</button>
                                   </div>
                                </div>



                                {this.state.newPassword && (<div className={'alert alert-warning margin-top-15'}>
                                    <p><strong>The NEW password for this number is: </strong> {this.state.newPassword}</p>
                                </div>)}

                                {this.state.authMsg && (<BootstrapAlert msg={this.state.authMsg}/>)}
                                
                                <h2>Credentials</h2>

                                {isMobileOrTablet && (<div>
                                    <a className="btn btn-sm btn-primary" href={deeplinkSnrbSchema}>Login to the App</a>
                                </div>)}

                                <h3>GrooVe IP App Login Credentials</h3>

                                <dl className="dl-horizontal">
                                    <dt>User Id</dt>
                                    <dd>{userName}</dd>
                                    <dt>Password</dt>
                                    <dd>{this.props.currentItem.Password}</dd>
                                </dl>

                                <h3>Sip Credentials</h3>

                                <dl className="dl-horizontal">
                                <dt>User Name</dt>
                                    <dd>{sipId}</dd>

                                    <dt>Password</dt>
                                    <dd>{this.props.currentItem.SipSecondary.SipPassword}</dd>

                                    <dt>Domain</dt>
                                    <dd>{this.props.currentItem.SipSecondary.Realm}</dd>
                                 
                                </dl>

                                {(this.props.hideActions) && (<div>

                                </div>)}{
                                    <a href={'../number/usage?nuid=' + this.props.currentItem.NumberUserId} className="pull-right">View Detailed Usage History</a>
                                }
                                
                        </div>
                    </div>

                    <div className="modal fade" id="delete-confirm-modal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Confirm Deletion</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="modal-body">
                                <p>Are you sure you want to delete this phone number.  Warning! This action cannot be undone!</p>
                                </div>
                                <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteNumber}>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="modal fade" id="reset-confirm-modal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">Confirm Password Reset</h4>
                                </div>
                                <div className="modal-body">
                                <p>Are you sure you want to reset the password for this phone number.  Warning! This action cannot be undone!</p>
                                </div>
                                <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.resetNumber}>Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }else {
            return(
                <div>
                    
                </div>
            );
        }
        
    }
}

export default NumberDetail;