import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import '../css/index.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as constants from '../utils/constants.js';
import BootstrapAlert from './bootstrapAlert.jsx';
import TermsOfService from './tos.jsx';
import progressSpinner from '../img/rolling.gif';
import Cookies from 'universal-cookie';
import publicIp from 'public-ip';
import firebase from '../utils/firebase.js';
const recaptchaRef = React.createRef();

class Registration extends React.Component{
    
    constructor(props, context) {
        super(props, context);
       
        this.state = {
            validEmail: null,
            validFirstName: null,
            validLastName: null,
            validOrganization: null,
            showProgressbar: false,
            isRecaptchaChecked: false,
            firstName: '',
            lastname: '',
            email: '',
            password: '',
            confirmPassword: '',
            organization: '',
            recaptchaResponse: '',
            resultMessage: null
        };

        this.validateFirstName = this.validateFirstName.bind(this);
        this.validateLastName = this.validateLastName.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateOrganization = this.validateOrganization.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateReEnterPassword = this.validateReEnterPassword.bind(this);
        this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount(){
    
        var publicIp = await publicIp.v4();
        this.setState({publicIpFour: publicIp});
    }
    

    validateFirstName (event) {

        const value = event.target.value;

        if (!value) {
            this.setState({ validFirstName: 'form-group has-error has-feedback', firstName: value });
        } else {
            this.setState({ validFirstName: 'form-group has-success has-feedback', firstName: value });
        }

        this.validateForm();
    }

    validateLastName (event) {
        
        const value = event.target.value;
        
        if (!value) {
            this.setState({ validLasttName: 'form-group has-error has-feedback', lastName: value });
        } else {
            this.setState({ validLastName: 'form-group has-success has-feedback', lastName: value });
        }
        
        this.validateForm();
    }

    validateOrganization (event) {

        var value = event.target.value;
        
        if (!value) {
            this.setState({ validOrganization: 'form-group has-warning has-feedback', organization: value });
        } else {
            this.setState({ validOrganization: 'form-group has-success has-feedback', organization: value });
        }

        this.validateForm();
    }

    validateEmail(event) {
        
        const value = event.target.value;
        
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var isEmailValid = re.test(value);
   
        if (!isEmailValid) {
            this.setState({ validEmail: 'form-group has-error has-feedback', email: value });
        } else {
            this.setState({ validEmail: 'form-group has-success has-feedback', email: value });
        }

        this.validateForm(); 
    }

    validatePassword(event) {

        const value = event.target.value;

        //var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        //var isPasswordValid = re.test(value);
        var isPasswordValid = false;
        if (value.length > 5) {
            isPasswordValid = true;
        }
       
        if (!isPasswordValid) {
            this.setState({
                validPassword: 'form-group has-error has-feedback',
                passwordHint: 'Password must be at least 6 characters long.',
                password: value
            });
        } else {
            this.setState({
                validPassword: 'form-group has-success has-feedback',
                passwordHint: null,
                password: value
            });
        }

        this.validateForm();
    }

    validateReEnterPassword(event) {

        const value = event.target.value;
        
        var password = this.refs.password.value.trim();
       
        if (value && password && value === password) {
            this.setState({
                validReEnterPassword: 'form-group has-success has-feedback',
                passwordMatchHint: null,
                confirmPassword: value
            });
        } else {            
            this.setState({
                validReEnterPassword: 'form-group has-error has-feedback',
                passwordMatchHint: 'Passwords must match.',
                confirmPassword: value
            });
        }

        this.validateForm();
    }

    onRecaptchaChange(value) {
        
        if(value){
            this.setState({recaptchaResponse: value, isRecaptchaChecked: true});
        }
        
        this.validateForm();
    }

    validateForm() {
   
        var isValid = false;
        
        var firstName = this.state.firstName;
        var lastName = this.state.lastName;
        var email = this.state.email;
        var password = this.state.password;
        var confirmPassword = this.state.confirmPassword;
        
        if (firstName && lastName && email && password && confirmPassword && this.state.isRecaptchaChecked) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var isEmailValid = re.test(email);
           
            if (isEmailValid) {
                if (password === confirmPassword) {
                    isValid = true;
                } 
            } 
        }

        this.setState({ submitEnabled: isValid });

        return isValid;
    }

    handleSubmit(e) {
        e.preventDefault();

        const cookies = new Cookies();

        var self = this;
        self.setState({ showProgressbar: true });
        if (!self.validateForm()) {
            return;
        }
     
        var response = this.state.recaptchaResponse;

        var firstName = this.state.firstName;
        var lastName = this.state.lastName;
        var organization = this.state.organization;
        var email = this.state.email;
        var password = this.state.password;
        var confirmPassword = this.state.confirmPassword;

        if (!organization) {
            organization = firstName + " " + lastName;
        }
    
        var self = this;
        
        if (!response) {
            self.setState({ 
                validationError : 'Please check the box indicating that you are not a robot.',
                showProgressbar: false
            });
            return;
        }

        if (!email || !password) {
            self.setState({
                validationError: false,
                showProgressbar: false
            });
            return;
        }

        axios.post(constants.REGISTRATION_URL, {
            FirstName : firstName , 
            LastName : lastName,
            Organization : organization,
            Email : email,
            Password: password,
            ConfirmPassword: confirmPassword,
            Response: response,
            IP : this.state.publicIpFour
        }).then(function(res) {
            
            if (res) {
                cookies.set(constants.COOKIE_EMAIL_KEY, res.data.email, { path: '/' , expires: constants.BEARER_EXPIRE_TIME });
                cookies.set(constants.COOKIE_BEARER_TOKEN, res.data.bToken, { path: '/' , expires: constants.BEARER_EXPIRE_TIME });
                cookies.set(constants.COOKIE_ID_KEY, res.data.payload.UserId, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
                var result = res.data.payload;
                if (result.status) {
                    if (result.status === "error") {
                        if (result.message.includes('Customer with this email already exists')) {
                            self.setState({
                                validationError: 'A customer with this email already exists',
                                showProgressbar: false
                            });
                        } else if (result.message.includes("Service Not Available in your area")) { 
                            self.setState({
                                validationError: 'Service Not Available in your area',
                                showProgressbar: false
                            });
                        } else {
                            self.setState({
                                validationError: 'Registration Error please try again',
                                showProgressbar: false
                            });
                        }
                    } else {

                        firebase.analytics().logEvent('registration_success', {
                            customer_id: res.data.payload.UserId
                        });

                        window.location.href = '../register/verify?email=' + email + '&status=sent';
                    }
                } else {
                    window.location.href = '../register/verify?email=' + email + '&status=sent';
                }

        } else if (result && result.message) {
            self.setState({
                validationError: result.message,
                showProgressbar: false
            });
        } else {
            self.setState({
                validationError: 'Registration Error please try again',
                showProgressbar: false
            });
        }
      }).catch(function (error) {
         

        if(error.response.data.Message){
            var msg = {
                description: error.response.data.Message,
                alertType: 'danger',
                isDismissible: true
            }
            self.setState({resultMessage : msg, showProgressbar: false});
        }else{
            var msg = {
                description: error.response.data,
                alertType: 'danger',
                isDismissible: true
            }
            self.setState({resultMessage : msg, showProgressbar: false});
        }

      });
        
    }

    render() {

        return(
            <div>
                <div className="card">
                    <div className="card-body">
                            
                        <div id="purchase-required-alert" className="alert alert-warning margin-top-15">
                            <h4>Purchase Required</h4>
                            <p>In order to start using the GrooVe IP service a <strong>Paypal</strong> account is required.</p>
                            <hr/>
                            <p>Please review our <a href="./pricing">pricing details</a> for more information.</p>
                        </div>
                        <h1>Register for GrooVe IP</h1>
                        <p className="col-md-offset-3"><strong>* Indicates a required field</strong></p>
                            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                <div className={this.state.validFirstName ? this.state.validFirstName : 'form-group'}>
                                    <label htmlFor="inputFirstName" className="col-sm-3 control-label">First Name*</label>
                                    <div className="col-sm-6">
                                    <input type="text" ref="firstName" value={this.state.firstname} onChange={this.validateFirstName} className="form-control" id="inputFirstName" placeholder="First Name" />
                                    </div>
                                </div>
                                <div className={this.state.validLastName ? this.state.validLastName : 'form-group'}>
                                    <label htmlFor="inputLasttName" className="col-sm-3 control-label">Last Name*</label>
                                    <div className="col-sm-6">
                                    <input type="text" ref="lastName" value={this.state.lastName} onChange={this.validateLastName} className="form-control" id="inputLastName" placeholder="Last Name" />
                                    </div>
                                </div>
                                <div className={this.state.validEmail ? this.state.validEmail : 'form-group' }>
                                    <label htmlFor="inputEmail" className="col-sm-3 control-label">Email*</label>
                                    <div className="col-sm-6">
                                    <input type="email" ref="email" value={this.state.email} onChange={this.validateEmail} className="form-control" id="inputEmail" placeholder="Email" />
                                    </div>
                                </div>
                                <div className={this.state.validOrganization ? this.state.validOrganization : 'form-group'}>
                                    <label htmlFor="inputOrganization" className="col-sm-3 control-label">Organization Name</label>
                                    <div className="col-sm-6">
                                    <input type="text" ref="organization" value={this.state.organization} onChange={this.validateOrganization} className="form-control" id="inputOrgName" placeholder="Organization Name" />
                                    </div>
                                </div>
                                <div className={this.state.validPassword ? this.state.validPassword : 'form-group'}>
                                    <label htmlFor="inputPassword" className="col-sm-3 control-label">Password*</label>
                                    <div className="col-sm-6">
                                    <input type="password" ref="password" value={this.state.password} title={this.state.passwordToolTip} onChange={this.validatePassword} className="form-control" id="inputPassword" placeholder="Password" aria-describedby="passwordRequirements" />
                                        <span id="passwordRequirements" className="help-block text-danger">{this.state.passwordHint ? this.state.passwordHint : null}</span>
                                    </div>
                                </div>
                                <div className={this.state.validReEnterPassword ? this.state.validReEnterPassword : 'form-group'}>
                                    <label htmlFor="inputReEnterPassword" className="col-sm-3 control-label">Re-Enter Password*</label>
                                    <div className="col-sm-6">
                                    <input type="password" ref="re_enter_password" value={this.state.confirmPassword} onChange={this.validateReEnterPassword} className="form-control" id="inputReEnterPassword" placeholder="Re-Enter Password" aria-describedby="passwordMatch" />
                                        <span id="passwordMatch" className="help-block text-danger">{this.state.passwordMatchHint ? this.state.passwordMatchHint : null}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-md-offset-3">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey="6LdUgF4UAAAAAB9wXppDM02-grmfccg-nOLgVJ0y"
                                            onChange={this.onRecaptchaChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-md-offset-3 margin-top-15">
                                            By clicking 'Register' you agree to our <a href="#" data-toggle="modal" data-target="#tos-modal">Terms and Conditions</a> and 
                                            certify that you are 16 years of age or older
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-md-offset-3">
                                        <button className={this.state.submitEnabled ? 'btn btn-primary margin-top-15' : 'btn btn-primary margin-top-15  disabled'} type="submit">Register</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-md-offset-3  margin-top-15">
                                        <p>Already have an account? <a href="./login">Login</a>.</p>
                                    </div>
                                </div>
                                
                                {this.state.showProgressbar && (<div>
                                    <img className="center-horizontal" src={progressSpinner} height="30" width="30" alt="loading" />
                                </div>)}
                                <div>
                                    {<BootstrapAlert msg={this.state.resultMessage}/>}
                                </div>
                            </form>

                        </div>
                    </div>
                
                
            <div className="modal fade" id="tos-modal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                               <div className="modal-header">
                                 <h4 className="modal-title">Terms and Conditions</h4>
                                 <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                               </div>
                              <div className="modal-body">
                                    <h3 className="paragraph-header">1. Terms of Use</h3>
                                    <TermsOfService/>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                              </div>
                            </div>
                        </div>
            </div>{/*modal*/}
            </div>
        );
    }
}

export default Registration;