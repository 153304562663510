import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import NavBar from './components/navbar.jsx'
import {navConfig} from './services/nav-configure-service.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faInfoCircle, faEye, faCheck, faCopy } from '@fortawesome/free-solid-svg-icons'
import './App.css';
import MobileDeeplink from './components/mobileDeeplink';
import LeftNav from './components/leftnav.jsx';
import Registration from './components/registration.jsx';
import Login from './components/login.jsx';
import AccountInfo from './components/accountInfo.jsx';
import Numbers from './components/numbers.jsx';
import Balance from './components/balance.jsx';
import NumberSearch from './components/numberSearch.jsx';
import Usage from './components/usage.jsx';
import Pricing from './components/pricing.jsx';
import PasswordReset from './components/passwordReset.jsx';
import PasswordChange from './components/passwordChange.jsx';
import RegistrationVerification from './components/registrationVerification.jsx'
import NumberPurchase from './components/numberPurchase.jsx';
import MarketingNavBar from './components/marketingNavBar';
import Home from './components/home.jsx'
import FAQ from './components/faq'
import ApiDocuments from './components/apiDocuments.jsx';
import { toast } from 'react-toastify';
import NotFound from './components/notFound.jsx';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Cookies from 'universal-cookie';

function App() {

  var currentpage = window.location.pathname;
  var isMobile = window.innerWidth <= 600;
  toast.configure();

  library.add(fab, faEye, faInfoCircle, faCheck, faCopy);

  var AuthenticatedContainer;
  
  if(!isMobile){
    AuthenticatedContainer = () => (
    
      <div>
        <NavBar navConfig={navConfig(currentpage)}/>
        <LeftNav navConfig={navConfig(currentpage)}/>
        <div className="container body-content">
          <Route path="/portal/account" component={AccountInfo} />
          <Route path="/portal/acount" component={AccountInfo} />   
          <Route path="/portal/numbers" component={Numbers} /> 
          <Route path="/portal/balance" component={Balance} />     
        </div>
      </div>
     )
  }else{
    AuthenticatedContainer = () => (
    
      <div>
        <NavBar navConfig={navConfig(currentpage)}/>
        <LeftNav navConfig={navConfig(currentpage)}/>
        <div className="container body-content margin-top-35">
          <Route path="/portal/account" component={AccountInfo} />
          <Route path="/portal/acount" component={AccountInfo} />   
          <Route path="/portal/numbers" component={Numbers} /> 
          <Route path="/portal/balance" component={Balance} /> 
          <Route path="/portal/mobilesignin" component={MobileDeeplink} />       
        </div>
      </div>
     )
  }

   const UnAuthenticatedContainer = () => (
    <div>
      <NavBar navConfig={navConfig(currentpage)}/>
      <div className="container body-content">
        <Route exact path="/register" component={Registration} />    
        <Route path="/login" component={Login} />   
        <Route path="/Account/Login" component={Login}/>
        <Route
          path='/pricing'
          render={(props) => <Pricing {...props} navstack={'register'} />}
        />
        <Route path="/password/reset" component={PasswordReset} />
        <Route path="/password/change" component={PasswordChange} />
        <Route path="/account/verify" component={RegistrationVerification} />
        <Route path="/register/verify" component={RegistrationVerification} />      
      </div>
    </div>
   )

   const NumberPurchaseFlowContainer = () => (
    <div>
      <NavBar navConfig={navConfig(currentpage)}/>
      <div className="container body-content">
        <Route path="/numbers/search" component={NumberSearch} />
        <Route path="/number/search" component={NumberSearch} />
        <Route path="/number/purchase" component={NumberPurchase} />  
        <Route path="/number/usage" component={Usage}/>     
      </div>
    </div>
   )
   
   const MarketingContainer = () => (
    <div>
      <MarketingNavBar/>
      <Route exact path="/" component={Home}/>
      <Route exact path="/faq" component={FAQ}/>
    </div>
   )
    

   const ApiContainer = () => (
    <div>
      <NavBar navConfig={navConfig(currentpage)}/>
      <Route exact path="/api-docs" component={ApiDocuments} />}/>
    
    </div>
   )
  
   function NoMatch({ location }) {
  
    return (
      <div>
        <NavBar navConfig={navConfig(currentpage)}/>
        <Route render={(props) => <NotFound location={location.pathname} />}/>
      </div>
    );
  }

  function OnAuthenticatedUserNavigate() {
    //read cookies add timeout here
    //const cookies = new Cookies();

    /*
    var token = cookies.get(constants.COOKIE_TOKEN_KEY);
        var custId = cookies.get(constants.COOKIE_ID_KEY);
        
        if (!token || !custId) {
            window.location.href = '../login';
            return;
        } else {
            cookies.set(constants.COOKIE_ID_KEY, custId, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
            cookies.set(constants.COOKIE_TOKEN_KEY, token, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
        }
    */
  }

  return (
      <div className="App">
        <Router>
          <Switch>
              <Route exact path="/" component={MarketingContainer}/>
              <Route exact path="/faq" component={MarketingContainer}/>
              <Route path="/api-docs" component={ApiContainer}/>
              <Route exact path="/register" component={UnAuthenticatedContainer}/> 
              <Route path="/Account/Login"><Redirect to="/login"/></Route>
              <Route exact path="/login" component={UnAuthenticatedContainer}/> 
              <Route exact path="/pricing" component={UnAuthenticatedContainer}/> 
              <Route path="/password" component={UnAuthenticatedContainer}/> 
              <Route path="/account/verify" component={UnAuthenticatedContainer} />
              <Route path="/register/verify"><Redirect to={"/account/verify"}/></Route>
              <Route path="/portal" component={AuthenticatedContainer} onEnter={OnAuthenticatedUserNavigate} onChange={OnAuthenticatedUserNavigate}/>      
              <Route path="/number" component={NumberPurchaseFlowContainer} onEnter={OnAuthenticatedUserNavigate} onChange={OnAuthenticatedUserNavigate}/>
              <Route path="/numbers" component={NumberPurchaseFlowContainer} onEnter={OnAuthenticatedUserNavigate} onChange={OnAuthenticatedUserNavigate}/>
              <Route component={NoMatch} />
          </Switch>
        </Router>
      </div>
  );
}

export default App;
