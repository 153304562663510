import React from 'react';

class TermsOfService extends React.Component{
    
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div>
                    <p>
                        Please read this Terms and Conditions document (“Agreement”) carefully before using the services
                        offered by SNRB Labs LLC (“Company”). This Agreement sets forth the legally binding terms and
                        conditions for your use of the websites owned and operated by Company, including, without
                        limitation, the <a href="https://www.snrblabs.com">snrblabs.com</a> website (“Sites”), the GrooVe IP application, and any other
                        features, content, or applications offered from time to time by Company in connection therewith
                        (collectively “Service”). By using the Sites or Service in any manner, you agree to be legally bound
                        by this Agreement. This Agreement applies to all users of the Sites or Service.
                    </p>
                    <h3 className="paragraph-header">2. Acceptance</h3>
                    <p>
                        The Service is offered subject to acceptance, without modification, of this Agreement and all other
                        operating rules, policies and procedures that may be published from time to time on the Sites by
                        Company.
                    </p>
                    <p>
                        The Service is available only to individuals who are 16 years of age or older. You represent and
                        warrant that you are of legal age to form a binding contract and are at least 16 years old, and that all
                        registration information you submit is accurate and truthful. Company may, in its sole discretion,
                        refuse to offer the Service to any person or entity and change its eligibility criteria at any time.
                    </p>
                    <h3 className="paragraph-header">3. Modifications</h3>
                    <p>
                        Company reserves the right, at its sole discretion, to modify or replace any of the Terms of Use in
                        this Agreement, or change, suspend, or discontinue the Service (including, without limitation, the
                        availability of any feature, or content) at any time by posting a notice on the Sites or by sending you
                        an email. Company may also impose limits on certain features and services or restrict your access
                        to parts or all of the Service without notice or liability.
                    </p>
                    <p>
                        If you don’t agree with the new Agreement, you are free to reject them. However, that means you will
                        not be able to use the Services. Your continued use of the Service following the posting of any
                        changes to this Agreement constitutes acceptance of all of the changes.
                    </p>
                    <h3 className="paragraph-header">4. Content</h3>
                    <p>
                        The materials displayed or performed or available on or through the Services, including, but not
                        limited to, text, graphics, images, etc. (all of the foregoing, the “Content”) may be protected by
                        copyright and/or other intellectual property laws. You promise to abide by all copyright notices,
                        trademark rules, and restrictions contained in any Content you access through the Services, and you
                        won’t use, distribute or otherwise exploit for any purpose any Content not owned by you, without the
                        prior consent of the owner of that Content or in a way that violates someone else’s (including
                        Company’s) rights.
                    </p>
                    <p>
                        Company owns the Services. You won’t modify, publish, or create derivative works based on, or
                        otherwise exploit any of the Services.
                    </p>
                    <h3 className="paragraph-header">5. Privacy</h3>
                    <p>
                        Company's current Sites and Service privacy statement is located at
                    <a href="https://snrblabs.com/GrooVeIp/PrivacyPolicy">snrblabs.com/GrooVeIp/PrivacyPolicy</a> (“Privacy Policy”) and is incorporated into this
                        Agreement. For inquiries in regard to the Privacy Policy, or to report a privacy related problem,
                        please contact <a href="mailto:snrb.labs@gmail.com">snrb.labs@gmail.com</a>.
                    </p>
                    <h3 className="paragraph-header">6. Acceptable Use</h3>
                    <p>
                        Company's current Acceptable Use Policy is located at
                    <a href="https://snrblabs.com/GrooVeIp/AcceptableUsePolicy">snrblabs.com/GrooVeIp/AcceptableUsePolicy</a> (“AUP”) and is incorporated into this Agreement.
                        For inquiries in regard to AUP, please contact <a href="mailto:snrb.labs@gmail.com">snrb.labs@gmail.com</a>.
                    </p>
                    <h3 className="paragraph-header">7. Maintenance</h3>
                    <p>
                        From time to time, Service may be interrupted or otherwise impacted for routine maintenance.
                        Company will use commercially reasonable efforts to perform such maintenance in a manner that
                        will not unreasonably interrupt the Service.
                    </p>
                    <h3 className="paragraph-header">8. Emergency Services</h3>
                    <p>
                        You expressly agree and understand that the Service is not intended to support or carry emergency
                        or time-critical calls (i.e. 911) or texts to any kind of emergency or time-critical service (“Emergency
                        Services”). Company, its affiliates or staff are in no way liable for such emergency calls or
                        communications. By agreeing to this Agreement you understand that additional arrangements must
                        be made to access Emergency Services. To access Emergency Services, you acknowledge and
                        accept your responsibility to purchase, separately from the Service, traditional telephone service or
                        other means of communication intended for access to Emergency Services. You recognize and
                        agree that Company is not required to offer Emergency Services or access thereto pursuant to any
                        applicable local and or national rules, regulation or law. You further acknowledge that Service is not
                        a replacement for your primary telephone service or any other means of communication.
                    </p>
                    <p>
                        If you associate Service and/or the telephone number that you obtain from Service with a Voice over
                        IP (VoIP) adapter, SIP phone and/or an analog telephone adapter, the following applies:  You (i)
                        must obtain emergency calling (i.e., 911 service) from a third party; and (ii) acknowledge and agree
                        that you have obtained such emergency calling (i.e., 911) service from a third party.
                    </p>
                    <h3 className="paragraph-header">9. Payments / Refunds</h3>
                    <p>
                        Depending on the Services you use or sign up for, you may be required to pay some fees or
                        earn/buy credits in connection with the Service. Should you elect to subscribe to such features, you
                        shall pay all applicable fees, as described on the Sites in connection with such features. If you make
                        a change in any Service, there may be different fees involved.
                    </p>
                    <p>
                        Company offers a Service that allows you to earn or purchase credits to be used for minutes and/or
                        messaging Services. Usage of minutes (whether free, earned or purchased) is calculated in one
                        minute increments. Credits are billed at the time of purchase. Unless prohibited by law and except
                        as expressly provided in this Agreement, any credits you earn or purchase will expire in thirty days
                        (or sixty days, as explained later in in the Account Inactivity / Number Ownership section of this
                        document) from your most recent date of use of the Service to place or receive a call or send or
                        receive a text message. Credits may not be transferred and will not be restored once expired.
                    </p>
                    <p>
                        Company will be under no obligation to offer any refunds or reimbursements for the purchase price
                        of any credits you purchase from Company or any fees you pay to Company.
                    </p>
                    <p>
                        Company reserves the right to change its pricing and to institute new charges at any time, upon ten
                        days prior notice to you, which may be posted on the Sites or other public channels. Use of the
                        Service by you following such notification constitutes your acceptance of any new or increased
                        charges.
                    </p>
                    <h3 className="paragraph-header">10. Account Inactivity / Number Ownership</h3>
                    <p>
                        You understand and agree that you do not own the phone number provided to you by the Company
                        (“Company Number”) and that you do not have a right to keep that Company Number if your account
                        is closed. Company may withdraw your Company Number at any time or for any reason upon notice
                        to you.
                    </p>
                    <p>
                        Company may reclaim the Company Number you received through the Service if you have not
                        placed or received calls or sent or received text messages (“Inactivity” or “Inactive”) for a period of
                        thirty days. There is an exception to this condition as explained below in the next paragraph.
                    </p>
                    <p>
                        If you have a Company Number and you have at least 25 earned or purchased credits in your
                        account, 25 credits will be deducted from your account following thirty days of Inactivity. If you don’t
                        have enough credits, or if your account has been Inactive for sixty consecutive days, all of your
                        remaining credits will be deducted, the Company Number assigned to you will be reclaimed and your
                        account will be deactivated.
                    </p>
                    <h3 className="paragraph-header">11. Termination</h3>
                    <p>
                        Company may in its sole discretion terminate your access to all or any part of the Service at any
                        time, with or without cause, with or without notice, effective immediately, which may result in the
                        forfeiture and destruction of all information associated with your membership.
                    </p>
                    <p>
                        If you wish to terminate your account, you may do so at your sole discretion, at any time, and
                        effective immediately.
                    </p>
                    <p>
                        Any fees paid or credits purchased hereunder are non-refundable.
                    </p>
                    <p>
                        All provisions of this Agreement which by their nature should survive termination shall survive
                        termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and
                        limitations of liability.
                    </p>
                    <h3 className="paragraph-header">12. International Use</h3>
                    <p>
                        Company makes no representation that the Service is appropriate or available for use in locations
                        outside of the USA, and accessing the Service is prohibited from territories where such Service is
                        illegal. If you access the Service from other locations, you do so at your own initiative and are
                        responsible for compliance with any applicable local laws.
                    </p>
                    <h3 className="paragraph-header">13. Entire Agreement</h3>
                    <p>
                        This Agreement is the entire agreement between you and Company with respect to the Service and
                        use of the Sites, and supersedes all prior communications (whether oral, written or electronic)
                        between you and Company.
                    </p>
                    <p>
                        If any provision of this Agreement is found to be unenforceable or invalid, that provision will be
                        limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain
                        in full force and effect and enforceable.
                    </p>
                    <p>
                        Nothing in this Agreement is intended to exclude or limit Company's liability for fraud or fraudulent
                        misrepresentation.
                    </p>
                    <p>
                        The failure of either party to exercise in any respect any right provided for herein shall not be
                        deemed a waiver of any further rights hereunder.
                    </p>
                    <h3 className="paragraph-header">14. Indemnification</h3>
                    <p>
                        You will defend, indemnify and hold Company, its subsidiaries and affiliates and their respective
                        directors, officers, employees, agents, successors and assigns harmless from and against any and
                        all actual or alleged costs, damages, expenses, losses, and/or liabilities of any kind, including,
                        without limitation, reasonable attorneys’ fees, arising from any action, claim, suit or proceeding
                        commenced by any third party for damages to any tangible property or bodily injury to or death of
                        any person arising out of or caused by your negligence or willful misconduct, except for those costs,
                        damages, expenses, losses, and/or liabilities of any kind caused by any act or omission of Company
                        or its directors, officers, employees, or agents. Company will promptly notify you in writing of any
                        such action, claim, suit or proceeding. You will control the response to any such action, claim, suit or
                        proceeding and the defense thereof, including, without limitation, any agreement relating to the
                        settlement thereof. In addition to the foregoing general indemnity, you will at all times defend,
                        indemnify and hold Company, its subsidiaries and affiliates and their respective directors, officers,
                        employees, agents, successors and assigns harmless from all claims arising out of or due to the
                        utilization by any other person or entity to which you provide any Service in connection with or
                        utilizing any Service provided to you pursuant to this Agreement, including, without limitation, due to
                        (i) Your failure to comply with any applicable laws; (ii) claims for libel, slander, and/or invasion of
                        privacy; (iii) claims for infringement of copyright and/or trademark; (iv) claims for infringement of
                        patents arising from combining or using Service or equipment furnished by Company with Service
                        and/or equipment furnished by any other person or entity; and (v) claims arising from any failure,
                        breakdown, interruption or deterioration of service provided by Company to you.
                    </p>
                    <h3 className="paragraph-header">15. Dispute Resolution</h3>
                    <p>
                        This Agreement shall be governed by and construed in accordance with the laws of the State of New
                        Jersey and the United States of America.
                    </p>
                    <p>
                        Any dispute arising from or relating to the subject matter of this Agreement shall be settled by
                        arbitration in Monmouth County, New Jersey. The prevailing party in the arbitration shall be entitled
                        to receive reimbursement of its reasonable expenses (including reasonable attorneys' fees, expert
                        witness fees and all other expenses) incurred in connection therewith.
                    </p>
                    <h3 className="paragraph-header">16. Warranty and Disclaimer</h3>
                    <p>
                        YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOU ARE USING THE SERVICE AT
                        YOUR OWN SOLE RISK. THE SERVICE IS PROVIDED <strong>"AS IS"</strong> WITHOUT WARRANTY, TERM
                        OR CONDITION OF ANY KIND, AND COMPANY DISCLAIMS ALL WARRANTIES, EITHER
                        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
                        SOME STATES DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE
                        LIMITATION MAY NOT APPLY TO YOU. COMPANY DOES NOT REPRESENT OR WARRANT
                        THAT THE USE OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
                        DEFECTS WILL BE CORRECTED, OR THAT THE SERVERS THAT MAKES THE SERVICE
                        AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY MATERIAL
                        DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE
                        AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                        COMPUTER SYSTEM OR NETWORK, OR LOSS OF DATA THAT RESULTS FROM USE OF THE
                        SERVICE
                    </p>
                    <p>
                        Many factors, including your local network, your internet connection, and the public internet, may
                        affect your ability to use the Services and the quality of your communications through the Services,
                        and may result in the failure of your communication. Company is not responsible for any Service
                        disruption, interruption or delay caused by any failure of or inadequacy in any of these factors or any
                        other items over which Company has no control. We make no representations or promises regarding
                        recommendations of services or products offered or purchased through the Services. Products and
                        Services purchased or offered through the Services are provided “as is” and without any warranty of
                        any kind from Company or others (unless provided expressly in writing by a designated third party
                        for a specific product).
                    </p>
                    <p>
                        Company will not be liable for the privacy of any information stored on Company’s equipment,
                        transmitted over networks accessed by the Sites, or otherwise connected with your use of the
                        Service. Some states and jurisdictions do not allow the preceding limitation of liability on the
                        protection of privacy to the extent personal information is stored on Company’s equipment; where
                        this is the case, the preceding limitations do not apply to you, but only to the extent such information
                        is stored on Company’s equipment.
                    </p>
                    <h3 className="paragraph-header">17. Limitation of Liability</h3>
                    <p>
                        COMPANY’S TOTAL LIABILITY WITH RESPECT TO YOUR USE OF THE SERVICE (INCLUDING,
                        BUT NOT LIMITED TO, LIABILITY ARISING OUT OF CONTRACT, TORT, STRICT LIABILITY,
                        BREACH OF WARRANTY OR OTHERWISE), WILL BE LIMITED TO THE FEES PAID BY YOU TO
                        COMPANY FOR THE SERVICE UTILIZED BY YOU PURSUANT TO THESE TERMS OF SERVICE
                        IN THE SIX MONTHS PRIOR TO THE ACT OF INJURY THAT GAVE RISE TO THE LIABILITY.
                        NEITHER COMPANY NOR ITS LICENSORS WILL BE LIABLE IN ANY EVENT FOR LOSS OR
                        INACCURACY OF DATA, LOSS OF PROFITS OR REVENUE, OR ANY DIRECT, INDIRECT,
                        SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION,
                        THE COST OF ANY SUBSTITUTE PROCUREMENT), WHETHER OR NOT FORESEEABLE AND
                        EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. CERTAIN
                        STATES AND/OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                        WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                        DAMAGES, SO THE EXCLUSIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.
                    </p>
                    <h3 className="paragraph-header">18. Other Terms</h3>
                    <p>
                        Company shall not be liable for any failure to perform its obligations hereunder where such failure
                        results from any cause beyond Company’s reasonable control, including, without limitation,
                        mechanical, electronic or communications failure or degradation.
                    </p>
                    <p>
                        This Agreement is not assignable or transferable by you except with Company's prior written
                        consent. Company may assign, transfer or delegate any of its rights and obligations hereunder
                        without consent.
                    </p>
                    <p>
                        All notices under this Agreement will be in writing and will be deemed to have been duly given when
                        received, if personally delivered or sent by certified or registered mail, return receipt requested;
                        when receipt is confirmed, if transmitted by facsimile or e-mail; or the day after it is sent, if sent for
                        next day delivery by recognized overnight delivery service.
                    </p>
                    <h3 className="paragraph-header">19. Copyright Notice</h3>
                    <p>
                        It is Company’s policy to block access to or remove Content that it believes in good faith to be
                        copyrighted material that has been illegally copied and distributed by any of our advertisers,
                        affiliates, content providers, members or users; and remove and discontinue service to offenders.
                    </p>
                    <h3 className="paragraph-header">20. Contact</h3>
                    <p>
                        The Service is operated and provided by SNRB Labs, LLC. If you have questions about these Terms
                        and Conditions please contact us at <a href="mailto:snrb.labs@gmail.com">snrb.labs@gmail.com</a>.
                    </p>
                    <p>(Update Effective as of: May 16, 2018)</p>
                </div>
        );
    }
}

export default TermsOfService;