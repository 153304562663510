import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as constants from '../utils/constants.js';
import progressSpinner from '../img/rolling.gif';
import BootstrapAlert from './bootstrapAlert.jsx';
import Cookies from 'universal-cookie';

class Login extends React.Component{
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            validationError: null,
            authMsg: null,
            showProgressbar: false,
            email: '',
            password: ''
        }

        var authState = constants.getParameterByName('state')
        if (authState) {
            if (authState === 'token_mismatch') {
                this.setState({ authMsg: 'Please login again' });
            } else if (authState === 'token_stale') {
                this.setState({ authMsg: 'For security purposes, please re-enter your email and password to continue.' });
            } else if (authState === 'token_expired') {
                this.setState({ authMsg: 'You have been logged out due to inactivity.' });
            } else if (authState === 'token_unknown') {
                this.setState({ authMsg: 'Please login again' });
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    async componentDidMount(){
    
        const cookies = new Cookies();
        
        let didLogout = constants.getParameterByName("lo")

        if (didLogout) {
            cookies.set(constants.COOKIE_ID_KEY, null, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
            cookies.set(constants.COOKIE_TOKEN_KEY, null, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });   
        } 
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value,
          isError: false
        });
     }

    handleSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        this.setState({
            validationError: null
        });

        var email = this.state.email;
        var password = this.state.password;
        
        if (!email || !password) {
            return;
        }
        
        var self = this;
        self.setState({ showProgressbar: true });

        
        axios.post(constants.LOGIN_URL, {   
                Email: email,
                Password: password
            }).then(function(res) {
                cookies.set(constants.COOKIE_EMAIL_KEY, email, { path: '/' , expires: constants.BEARER_EXPIRE_TIME });
                if (res) {
                    cookies.set(constants.COOKIE_ID_KEY, res.data.payload.CustomerId, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
                    cookies.set(constants.COOKIE_TOKEN_KEY, res.data.payload.Token, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
                    cookies.set(constants.COOKIE_BEARER_TOKEN, res.data.bToken, { path: '/' , expires: constants.BEARER_EXPIRE_TIME });

                    window.location.href = '../portal/account';
                }
            }).catch(function (error) {
                if(error.response){
                    if(error.response.data.Message){
                        if(error.response.data.Message === 'Forbidden') {
                            window.location.href = '../Account/Verify?email=' + email + '&status=forbidden';
                        }
    
                        var msg = {
                          description: error.response.data.Message,
                          alertType: 'danger',
                          isDismissible: true
                        }
                        self.setState({resultMessage : msg, showProgressbar: false});
                    }else{
                      var msg = {
                          description: error.response.data,
                          alertType: 'danger',
                          isDismissible: true
                        }
                        self.setState({resultMessage : msg, showProgressbar: false});
                  }
                }else {
                    var msg = {
                        description: 'Unable to connect to server',
                        alertType: 'danger',
                        isDismissible: true
                      }
                      self.setState({resultMessage : msg, showProgressbar: false});
                }
                
      
            });
    }

    render(){
        return(
            <div>

            <form className="form-signin" onSubmit={this.handleSubmit}>
                <h2 className="form-signin-heading">Please sign in</h2>
                <label htmlFor="inputEmail" className="sr-only">Email address</label>
                <input value={this.state.email} name="email" type="email" id="inputEmail" className="form-control" onChange={this.handleInputChange} placeholder="Email address" required />
                <label htmlFor="inputPassword" className="sr-only">Password</label>
                <input value={this.state.password} name="password" type="password" id="inputPassword" className="form-control" onChange={this.handleInputChange} placeholder="Password"  required />
                <button className="btn btn-lg btn-primary btn-block margin-top-15" type="submit">Sign in </button>
                <p>Don't have an account? <a href="./register">Register to get started</a>.</p>
                <p>Forgot your password? <a href="../password/reset">Reset it</a>.</p>
                {this.state.showProgressbar && (<img className="center-horizontal" src={progressSpinner} height="30" width="30" alt="loading" />)}  
                <div>
                    {<BootstrapAlert msg={this.state.resultMessage}/>}
                </div>
            </form>
        </div>);
    }
}

export default Login;

