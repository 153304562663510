export const navConfig = (currentPage) =>  {

    var navConfig = {
        "wordMark": "SNRB Portal",
        "leftMenuItems" : [
            { label : "Account", url : "/portal/account" ,isActive: false },
            { label : "Numbers", url : "/portal/numbers", isActive: false },
            { label : "Balance", url : "/portal/balance", isActive: false },
            { label : "Logout",  url : "/login?lo=true", isActive: false }
    ]};
  
    if(navConfig.items){
        navConfig.items = navConfig.items.map((n) => {
            if(n.url === currentPage){
                n.isActive = true;
            }
    
            return n;
        });
    }

    if(navConfig.leftMenuItems){
        navConfig.leftMenuItems = navConfig.leftMenuItems.map((n) => {
            if(n.url === currentPage){
                n.isActive = true;
            }
    
            return n;
        });
    }
    
    return navConfig; 
    
}
  
