import React from 'react';

class NormalizedNumber extends React.Component{
    
    constructor(props) {
        super(props);
    }

    render () {
        if (this.props.number) {
            var number = this.props.number.trim();
            if (number.startsWith('+1')) {
                number = number.replace('+1', '');
                var areaCode = number.substr(0, 3);
                var prefix = number.substr(3, 3);
                var suffix = number.substr(6, 4);
                number = areaCode + '-' + prefix + '-' + suffix;
            } else if(number.startsWith('+')) {
                var countryCode = number.substr(0, 2);
                var remainder = number.substr(2, number.length - 1);
                number = countryCode + '-' + remainder;
            } else {
                var areaCode = number.substr(0, 3);
                var prefix = number.substr(3, 3);
                var suffix = number.substr(6, 4);
                number = areaCode + '-' + prefix + '-' + suffix;
            }

            return (<span>{number}</span>);
        } else {
            return (<span></span>);
        }
    }
}

export default NormalizedNumber;