import React from 'react';
import axios from 'axios';
import * as constants from '../utils/constants.js';
import Cookies from 'universal-cookie';
import { BrowserRouter as Link } from "react-router-dom";
import NumberDetail from './numberDetails.jsx';
import BootstrapAlert from './bootstrapAlert.jsx';
import publicIp from 'public-ip';

class NumberPurchase extends React.Component {
    
    constructor(props, context){
        super(props, context);

        let number = constants.getParameterByName('selected').trimLeft();
        let areaCode = constants.getParameterByName('areaCode');
        this.reserve = this.reserve.bind(this);
        
        this.state = {
            isReserving: true,
            isPurchaseComplete: false,
            selectedNumber: number,
            searchedAreaCode : areaCode
        };
        this.reserve();
        
    }

    async componentDidMount(){
    
        var publicIp = await publicIp.v4();
        this.setState({publicIpFour: publicIp});
    }

    reserve(){
        const cookies = new Cookies();
        var token = cookies.get('snrb_token');
        var custId = cookies.get('snrb_cust_id');
        
        axios({
            method: 'post',
            url: constants.NUMBER_RESERVE_URL,
            headers: {
                'Authorization': cookies.get(constants.COOKIE_BEARER_TOKEN)
            },
            data : {
                customerId: custId,
                phoneNumber : this.state.selectedNumber,
                areaCode : this.state.searchedAreaCode,
                token: token,
                email: cookies.get(constants.COOKIE_EMAIL_KEY),
                ClientIp: this.state.publicIpFour
            }
        }).then((response) => {
           
            this.setState({
                isPurchaseComplete: true,
                isReserving: false,
                purchasedNumber: response
            })
            
        }).catch((error => {
            
            if (error.message.startsWith('token_')) {
                cookies.set(constants.COOKIE_ID_KEY, '', { expires: 0 });
                cookies.set(constants.COOKIE_TOKEN_KEY, '', { expires: 0 });
                cookies.set(constants.COOKIE_EMAIL_KEY, '', { expires: 0 });
                window.location.href = '../../login?state=' + error.message;
            } else {
                
                var errorMsg = {
                    description: error.message,
                    alertType: 'danger',
                    isDismissible: true
                }

                this.setState({
                    authMsg: errorMsg,
                    isReserving: false
                });  
            }
        }));
    
    }

    render(){
        
        return(<div>
            
            {this.state.isPurchaseComplete && (<div>
                <h2 className="form-signin-heading">Purchase Complete</h2>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href='../portal/numbers'>Numbers</a></li>
                    <li className="breadcrumb-item"><a href='./number/search'>Search</a></li>
                    <li className="breadcrumb-item active">Purchase</li>
                </ol>

                <div className="card">
                    <div className="card-body">
                        <NumberDetail currentItem={this.state.purchasedNumber.data} hideActions="true" />
                        
                        <a className="btn btn-primary margin-top-15 margin-left-15" href="../portal/numbers">Return to my numbers</a>
                                
                    </div>
                </div>
            </div>)}

            
            
            {this.state.isReserving && (
                <div>
                    <h2>Number Purchase in Progress</h2>
                    <p>Please do not close your browser or hit the back button.</p>
                    <div className="loading">Loading&#8230;</div>
                </div>)}

            {this.state.authMsg && (<BootstrapAlert msg={this.state.authMsg}/>)}

        </div>);
    }
}

export default NumberPurchase;