import React from 'react';
import axios from 'axios';
import * as constants from '../utils/constants.js';
import BootstrapAlert from './bootstrapAlert.jsx'
import progressSpinner from '../img/rolling.gif'

class PasswordChange extends React.Component{

    constructor(props, context){
        super(props, context);

        this.state = { 
            email: constants.getParameterByName('email'),
            password: '',
            confirmPassword: '',
            token: constants.getParameterByName('token'),
            isComplete: false
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value,
          isError: false
        });
     }

    handleSubmit(e){
        e.preventDefault();

        if (!this.state.password || !this.state.confirmPassword) {
            return;
        }

        this.setState({isSubmited: true});

        var self = this;

        axios.post(constants.PASSWORD_CHANGE_URL, {   
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            token: this.state.token
        }).then((response) => {
            self.setState({isComplete: true, isSubmited: false});
            var msg = {
                description: 'Your password has been changed.',
                alertType: 'success',
                isDismissible: false
              }
            
            self.setState({resultMessage : msg, isSubmited: true});
        }).catch((error => {
            self.setState({isComplete: true, isSubmited: false});
            var msg = {
                description: error.message,
                alertType: 'danger',
                isDismissible: true
              }
            
            self.setState({resultMessage : msg});
        }));

    }

    render(){
        return(
            <div>

            <form className="form-signin" onSubmit={this.handleSubmit}>
                <h4 className="form-signin-heading">Enter Your New Password</h4>
                <label htmlFor="intputPassword" className="sr-only">Password</label>
                <input value={this.state.password} name="password" type="password" id="inputPassword" className="form-control" onChange={this.handleInputChange} placeholder="Password" required />
                <label htmlFor="intputConfirmPassword" className="sr-only">Password</label>
                <input value={this.state.confirmPassword} name="confirmPassword" type="password" id="inputConfirmPassword" className="form-control" onChange={this.handleInputChange} placeholder="Confirm your password" required />
                <button className="btn btn-lg btn-primary btn-block margin-top-15" type="submit">Change Password</button>
                {this.state.isSubmited && (<img className="center-horizontal margin-top-15" src={progressSpinner} height="30" width="30" alt="loading" />)}  
                {this.state.isComplete && (
                <div>
                    <BootstrapAlert msg={this.state.resultMessage}/>
                    <a href="../../login">Return to Login</a>
                </div>
                )}  
                
            </form>
        </div>);
    }
}

export default PasswordChange;
