import React from 'react';
import '../css/index.css';
import axios from 'axios';
import $ from "jquery";
import * as constants from '../utils/constants.js';
import BootstrapAlert from './bootstrapAlert.jsx'
import BalanceHelp from './balanceHelp.jsx'
import Cookies from 'universal-cookie';
import copy from 'copy-to-clipboard';
import itunesLogo from '../img/itunes_app_store.png';
import amazonMarketLogo from '../img/AmazonMarketDownload.png';
import googlePlayLogo from '../img/google-play-badge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

class AccountInfo extends React.Component{
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            organizationName: 'None',
            key: '',
            keyValue: '',
            Credits: '',
            callbackUrlMsg: '',
            callbackUrl: '',
            numberCount: -1,
            isLoading: true
        }

        this.toogleShowKey = this.toogleShowKey.bind(this);
        this.getAccountInfo = this.getAccountInfo.bind(this);
        this.goToSearchNumbers = this.goToSearchNumbers.bind(this);
        this.goToAddCredets = this.goToAddCredets.bind(this);

        this.getAccountInfo();
    }

    goToSearchNumbers() {
        window.location.href = constants.BASE_PORTAL_URL + 'numbers/search';
    }
    
    goToAddCredets() {
        window.location.href = constants.BASE_PORTAL_URL + 'portal/balance';
    }

    toogleShowKey() {
       
        if (this.state.key === this.state.keyValue) {
            var hiddenKey = '';
            for (var i = 0; i < this.state.key.length; i++) {
                hiddenKey += '*'
            }
            this.setState({key: hiddenKey});
        } else {

            var self = this;
            this.setState({key: self.state.keyValue});
        }
    }

    copyKey(key) {
        if(key){
            copy(this.state.keyValue);
            toast("API Key copied to clipboard", { type: toast.TYPE.INFO, hideProgressBar: true, autoClose: 2500});
        }
    }

    copyCustomerId(customerId) {
        if(customerId){
            copy(this.state.customerId);
            toast("Customer ID copied to clipboard", { type: toast.TYPE.INFO, hideProgressBar: true, autoClose: 2500});
        }
    }

    getAccountInfo() {
        
        var self = this;
        if(process.env.NODE_ENV !== 'production'){
           
            setTimeout(function() {
                self.setState({
                    isLoading: false
                });
            }, 2000);
            
        }

        const cookies = new Cookies();
        var token = cookies.get(constants.COOKIE_TOKEN_KEY);
        var custId = cookies.get(constants.COOKIE_ID_KEY);
        
        if(!token || token === "null"){
            token = constants.getParameterByName('tk');
        }

        if (!token || !custId) {
            window.location.href = '../login';
            return;
        } else {
            cookies.set(constants.COOKIE_ID_KEY, custId, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
            cookies.set(constants.COOKIE_TOKEN_KEY, token, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
        }

        var self = this;

        
        

        axios.get(constants.getAccountInfoUrl(custId, token))
            .then((response) => {
               
                var hiddenKey = '';
                    for(var i = 0; i < response.data.Key.length; i++){
                        hiddenKey += '*'
                    }
                    var displayDollars = response.data.Dollars.toFixed(2)
                    displayDollars = '$' + displayDollars;
                    self.setState( { 
                        organizationName: response.data.OrganizationName,
                        key: hiddenKey,
                        keyValue: response.data.Key,
                        credits: displayDollars,
                        numberCount: response.data.NumberCount,
                        isLoading: false,
                        customerId: custId
                    });
                                        
                    if (response.data.NumberCount === 0) {

                        var balance = displayDollars.substr(1);
                       
                        if (parseFloat(balance) <= 0.0) {
                            $('#balance-call-to-action').modal('show');   
                        }  else {
                            $('#numbers-call-to-action').modal('show');
                        }
                    } 

            }).catch(error => {
      
                if (error.message.startsWith('token_')) {
                    cookies.set(constants.COOKIE_ID_KEY, '', { expires: 0 });
                    cookies.set(constants.COOKIE_TOKEN_KEY, '', { expires: 0 });
                    cookies.set(constants.COOKIE_EMAIL_KEY, '', { expires: 0 });
                    window.location.href = '../login?state=' + error.message;
                } else {

                    var msg = {
                        description: error.message,
                        alertType: 'danger',
                        isDismissible: false
                    }

                    self.setState(
                    { 
                        validationError: msg, 
                        isLoading: false
                    });
                }

            });
    }

    render(){

        var keyInput;
        var creditBalance;

        if(!this.state.isLoading){
            keyInput = (
                <div>
                    <div className="row margin-left-15">
                        <span className="account-info-company-name">{this.state.organizationName}</span>
                    </div>
                    <div className="row margin-left-15 margin-top-15">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text " id="input-start-date">Customer ID</span>
                            </div>
                            <input className="form-control" type="text" placeholder={this.state.customerId} readOnly ></input>
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <a href="#">
                                        <i id="copy-past-customer-id" className="material-icons iconalign" onClick={() => this.copyCustomerId(this.state.customerId)} role="button"><FontAwesomeIcon icon="copy"  /></i>                
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row margin-left-15 margin-top-15">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text " id="input-start-date">Key</span>
                            </div>
                            <input className="form-control" type="text" placeholder={this.state.key} readOnly ></input>
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <a href="#">
                                        <i id="show-hide-key" className="material-icons iconalign" onClick={this.toogleShowKey} role="button"><FontAwesomeIcon icon="eye"  /></i>                
                                    </a>
                                </span>
                                <span className="input-group-text">
                                    <a href="#">
                                        <i id="copy-past-key" className="material-icons iconalign" onClick={() => this.copyKey(this.state.keyValue)} role="button"><FontAwesomeIcon icon="copy"  /></i>                
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
                );
            creditBalance = <p className='credit-display-large'>{this.state.credits }</p>;
        }else {
            keyInput = (
                <div> 
                    <div className="col-md-12">
                        <div className="ph-item">
                            <div className="ph-col-12">
                                <div className="ph-row">
                                    <div className="ph-col-4 big" />
                                    <div className="ph-col-6 empty" />
                                    <div className="ph-col-10 big" />
                                    <div className="ph-col-8 big" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            );
            creditBalance = (
            <div>
                <div className="ph-item">
                    <div class="ph-row">
                        <div className="ph-col-4 empty"></div>
                        <div className="ph-col-4 empty"></div>
                        <div className="ph-col-4 empty"></div>
                        <div className="ph-col-12"></div>
                        <div className="ph-col-4 empty"></div>
                        <div className="ph-col-4 empty"></div>
                        <div className="ph-col-4 empty"></div>
                    </div>
                </div>
            </div>
            );
        }

        return(
        <div>
            <h1>Account Overview</h1>

            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div  className="card-body">
                            <h3 className="card-title">Access Information <a href="#" data-toggle="modal" data-target="#secret-key-account-info">
                                    <FontAwesomeIcon icon="info-circle" size="xs" />
                                </a>
                            </h3>

                            {keyInput}
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            
                            <h3 className="card-title">Balance <a href="#" data-toggle="modal" data-target="#balance-account-info"><FontAwesomeIcon icon="info-circle" size="xs" /></a></h3>
                            
                            <p className='credit-large-label'>Balance Remaining</p>
                        
                            {creditBalance}
                            
                        </div>
                    </div>
                    {this.state.validationError && (<BootstrapAlert msg={this.state.validationError}/>)}
                </div>

                <div className="col-md-6">

                    <div className="card">
                            
                            <div className="card-body">
                            <h3 className="card-title">App Download </h3>
                                <h5>Download from the iTunes App Store</h5>
                                <div className="row">

                                    <div className="col-md-12">

                                        <a href='https://itunes.apple.com/us/app/groove-ip/id1291925470?ls=1&mt=8'>
                                            <img width="185" height="65" className="store-logo" src={itunesLogo} alt="iTunes App Store" />
                                        </a>

                                    </div>

                                </div>

                                <h5>Download from Google Play</h5>
                                <div className="row">

                                    
                                        <div className="col-md-12">
                                            <a href='https://play.google.com/store/apps/details?id=com.snrblabs.grooveip&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                                <img alt='Get it on Google Play' className="store-logo" width="185" height="65" src={googlePlayLogo} />
                                            </a>
                                        </div>
                                    

                                </div>

                                <h5>Download from the Amazon Store</h5>
                                <div className="row">

                                    <div className="col-md-12">

                                        <a href="https://www.amazon.com/GrooVe-IP-VoIP-Calls-Text/dp/B007HA7A18/">
                                            <img  width="185" height="75" className="store-logo"  src={amazonMarketLogo} alt="download from the android market" />
                                        </a>
                                    </div>

                                </div>

                            </div>
                    </div>

                </div>

              </div>

              <div className="modal fade" id="balance-modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">Terms and Conditions</h4>
                        </div>
                        <div className="modal-body">
                            <h3 className="paragraph-header">1. Terms of Use</h3>
                            {/*<TermsOfService />*/}
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="balance-account-info" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Remaining Balance Information</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <BalanceHelp />
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="secret-key-account-info" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Access Information</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>        
                        </div>
                        <div className="modal-body">
                            <p>
                                This is the Secret Key needed for developers to use
                                the <a href="https://snrblabs.com/Commercial/API">GrooVe IP Commercial API</a>. 
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="balance-callback-url-modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Remaining Balance Information</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <BalanceHelp />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="numbers-call-to-action" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Get Started</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <h3 className="paragraph-header">Search for a Number to get Started!</h3>
                            <p>
                                You can use your account balance to purchase a number.
                                In order to get started you must search for a number.
                                Click "Search Numbers" to get started now.
                                </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.goToSearchNumbers}>Search Numbers</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal fade" id="balance-call-to-action" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Get Started</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <h3 className="paragraph-header">Add a Balance to Your Account to get Started</h3>
                            <p>
                                In order to get started you must search for a number.
                                However purchasing a number requires an account balance.
                                Click "Add Balance" to get started now.
                                </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.goToAddCredets}>Add Balance</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>);
    }
}

export default AccountInfo;