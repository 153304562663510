import React from 'react';
import heroImage from '../img/hero_image.png';

class Home extends React.Component{
    
    constructor(props, context) {
        super(props, context);
    }

    render(){
        return(
            <div>            
                <div id="home">
                    <h1>Ditch your Phone Plan</h1>
                    <h2>Get a new phone number on your <br/>existing device for one dollar</h2>
                    
                    
                    <div className="container">
                        <div className="row">
                            <div className="col text-center">
                                <a id="register-call-to-action" href="./register" className="btn btn-primary" >Register to Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;