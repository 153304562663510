import React from 'react';
import axios from 'axios';
import * as constants from '../utils/constants.js';
import BootstrapAlert from './bootstrapAlert.jsx'

class PasswordReset extends React.Component{

    constructor(props, context){
        super(props, context);

        this.state = { 
            email: '',
            isSubmited: false
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value,
          isError: false
        });
     }

    handleSubmit(e){
        e.preventDefault();

        if (!this.state.email) {
            return;
        }

        var self = this;

        axios.post(constants.PASSWORD_RESET_URL, {  
            data: {
                email: this.state.email
            } 
        }).then((response) => {
            var msg = {
                description: 'A link to reset your password has been sent to your registration email ' + this.state.email + '.  Please use the link to reset your password',
                alertType: 'success',
                isDismissible: false
              }
            
            self.setState({resultMessage : msg, isSubmited: true});
        }).catch((error => {
            var msg = {
                description: error.message,
                alertType: 'danger',
                isDismissible: true
              }
            
            self.setState({resultMessage : msg});
        }));

    }

    render(){
        return(
            <div>

            <form className="form-signin" onSubmit={this.handleSubmit}>
                <h2 className="form-signin-heading">Enter Your Email</h2>
                <label htmlFor="inputEmail" className="sr-only">Email address</label>
                <input value={this.state.email} name="email" type="email" id="inputEmail" className="form-control" onChange={this.handleInputChange} placeholder="Email address" required />
                <button className="btn btn-lg btn-primary btn-block margin-top-15" type="submit">Send Password Reset Link</button>
                {this.state.resultMessage && (<BootstrapAlert msg={this.state.resultMessage}/>)}
            </form>
        </div>);
    }
}

export default PasswordReset;