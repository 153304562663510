import React from 'react';
import ReactEmbedGist from 'react-embed-gist';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

class ApiDocuments extends React.Component{
    
    constructor(props, context) {
        super(props, context);
    }

    render(){
        return(<div id="api-docs" className="container">
            
            <div>

                <h1>GrooVe IP API Documentation</h1>
                <p>
                    GrooVe IP provides a REST API for commercial clients who build their own platforms using our services.btn-primary
                    We have provided a guide on how to use this API including authentication mechanisms, code samples, and end-point documentation 
                    based on the OpenAPI Specification
                </p>

                <h2>Authentication</h2>
                <h3>HTTPS</h3>
                <p>
                All communication with SNRB Labs API is done through secure channels that use HTTP with SSL. In addition to using HTTPS, we also utilize the following three mechanisms to prevent unauthorized use of the API.
                </p>

                <h3>SHA-256 Hash</h3>
                <p>
                    You will need to create a SHA-256 hash of the parameters passed in a particular API and your Secret Key. 
                    This hash needs to be passed in the API to the SNRB Labs server. The server will create a hash in a similar manner—if the created hash does not match the hash passed in the API, the API will be rejected due to authentication failure. 
                </p>

                <p>
                    To create the hash, please concatenate all the parameters being passed in the API (in the order they are presented in this document), whether in the URL itself or in the JSON array. Add your Secret Key to the end of this string. Make sure there are no spaces or extra characters added between the parameters and/or the Secret Key when concatenating them. 
                    Create the SHA-256 hash of this string and pass it in the API.
                </p>

                <p>
                    Please note that this hashing mechanism ensures that your Secret Key never goes over the “wire.”. <br/>
                    <strong>It should never be stored in an unecrypted format or visible in any public source code</strong>. 
                </p>

                <h3>Nonce</h3>
                <p>We use a Request ID parameter in all the critical API calls. This is a unique and random identifier that should be used one time only. This ensures that an old API message cannot be used in “replay attacks.”</p>

                <h3>IP Address White-listing</h3>
                <p>Although not mandatory, we recommend that you use one server to invoke our APIs to communicate with our server(s). You can provide the IP address of this server to us. We will white-list this IP address and ignore API communication from all other IP addresses that use your Customer ID. Alternatively, instead of one IP address, you may provide us multiple IP addresses 
                    or a range of IP addresses that should be white-listed by us.</p>

                <h2>Authentication Samples</h2>
                <p>All examples are using the endpoint /api/numbers/list/{'{customerId}'}/areaCode/{'{areaCode}'}/requestId/{'{nonce}'}/hash/{'{hash value of customerId + areaCode + requestId'}}"</p>

                <p>
                    We have create authentication examples in server popular languages.  We have included code snittps below, but you can find 
                    even more detail for each languages example on GitHub
                </p>

                <div>
                    <ul className="nav nav-pills" id="code-examples-pills" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#javascript-example" data-toggle="pill" role="tab" aria-controls="javascript-example" aria-selected="true">JavaScript</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#java-example" data-toggle="pill" role="tab" aria-controls="java-example" aria-selected="false">Java</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#kotlin-example" data-toggle="pill" role="tab" aria-controls="kotlin-example" aria-selected="false">Kotlin</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#csharp-example" data-toggle="pill" role="tab" aria-controls="csharp-example" aria-selected="false">C&#35;</a>
                        </li>
                    </ul>

                    <div className="tab-content" id="code-examples">
                        <div role="tabpanel" className="tab-pane fade show active" id="javascript-example">
                            <ReactEmbedGist gist="palburtus/085d4b4127b00b56de046bd26657f5eb" />      
                            <div className="media">
                                <img className="align-self-start mr-3" src="..." alt="Generic placeholder image"/>
                                <div className="media-body">
                                    <h5 className="mt-0">JavaScript Sample on GitHub</h5>
                                    <p>
                                        A sample NodeJS application, with authentication code and API requests is available on our 
                                        <a href="https://github.com/palburtus/grooveip_android_sample">GitHub page</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="java-example">
                            <ReactEmbedGist gist="palburtus/50e771ad5b59274c6694d915ee273697" />
                            <img className="align-self-start mr-3" src="..." alt="Generic placeholder image"/>
                            <div className="media-body">
                                <h5 className="mt-0">JavaScript Sample on GitHub</h5>
                                <p>
                                    A sample Android app, written in Java, with this authentication code and API request is available on our
                                    <a href="https://github.com/palburtus/grooveip_android_sample">GitHub page</a>.
                                </p>
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="kotlin-example">
                            <ReactEmbedGist gist="palburtus/58f30b4ca165f5fd28c3223df113e2b7" />        
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="csharp-example">
                            <ReactEmbedGist gist="palburtus/5acc4501829d7258ebd6537ea2a4ca9e" />      
                        </div>
                    </div>
                </div>
                
            </div>
            <SwaggerUI url="https://portal.snrblabs.com/swagger.json" docExpansion="list"/>
        </div>)
    }
}

export default ApiDocuments;