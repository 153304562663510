import React from 'react';
import '../css/index.css';
import '../css/fullScreenLoading.css'
import * as constants from '../utils/constants.js';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {Link} from "react-router-dom";
import BootstrapAlert from './bootstrapAlert.jsx'
import NormalizedNumber from './normalizedNumber.jsx'
import NumbersLoading from './numbersLoading.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class NumberSearch extends React.Component{
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            resultNumbers: null,
            validationError: null,
            isBalanceError: false,
            hideSearch: false,
            authMsg: null,
            searchedAreaCode: null,
            selectedNumber: null,
            isNumberSelected: false,
            isSearching: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.selectNumber = this.selectNumber.bind(this);
        this.search = this.search.bind(this);
        this.reserve = this.reserve.bind(this);
    }

    async componentDidMount(){
    
        
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value,
          isError: false
        });
     }

    search(){
        const cookies = new Cookies();
        var token = cookies.get('snrb_token');
        var custId = cookies.get('snrb_cust_id');
        
        this.setState({
            hideSearch: true,
            isSearching: true
        })
        
        if (!token || !custId) {
            window.location.href = '../login';
            return;
        } else {
            cookies.set(constants.COOKIE_ID_KEY, custId, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
            cookies.set(constants.COOKIE_TOKEN_KEY, token, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });       
        }

        if(this.state.searchedAreaCode){
            
        }

        var self = this;

        axios.get(constants.getNumbersSearchUrl(custId, this.state.searchedAreaCode, token))
            .then((response) => {
                
                if (response.data) {
                    if (response.data.length > 0) {
                        self.setState({
                            resultNumbers: response.data,
                            hideSearch: false,
                            isSearching: false,
                            authMsg: null
                        });
                    }
                }
            }).catch((error => {

                var description;

                if(error.message === 'Request failed with status code 406'){
                    description = 'No phone numbers avilable for this area code';
                }else {
                    description = error.message;
                }

                var errorMsg = {
                    description: description,
                    alertType: 'danger',
                    isDismissible: true
                }

                self.setState({
                    isSearching: false,
                    authMsg: errorMsg,
                    hideSearch: false
                });
            }));
    }

    selectNumber(number){
        
        this.setState({
            selectedNumber: number,
            isNumberSelected: true
        });
    }

    reserve(){
        window.location.href = '../number/purchase?selected=' + this.state.selectedNumber + '&areaCode=' + this.state.searchedAreaCode;
    }

    render(){
        
        var numbers = [];
        if(this.state.resultNumbers){
            numbers = this.state.resultNumbers.map((n, i) => {
                if(this.state.selectedNumber && this.state.selectedNumber === n){
                    return <div>
                                <li className="list-group-item selected-number" onClick={() => this.selectNumber(n)} key={i}>
                                <strong><NormalizedNumber number={n}/></strong> <FontAwesomeIcon icon="check"/>
                                </li><span aria-hidden="true"></span>
                            </div>
                }else{
                    return  <div>
                                <li className="list-group-item" onClick={() => this.selectNumber(n)} key={i}>
                                    <NormalizedNumber number={n}/>
                                </li>
                            </div>
                }
            });
        }
        
        return(
            <div>
                <h2 className="form-signin-heading">Search for Numbers</h2>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='../portal/numbers'>Numbers</Link></li>
                    <li className="breadcrumb-item active">Search</li>
                </ol>
                
                {!this.state.hideSearch && (
                <div className="col-md-6">
                    <div className="form-inline">
                        <div className="from-group mb-2">
                            <label htmlFor="searchedAreaCode" className="sr-only">Area Code</label>
                            <input value={this.state.searchedAreaCode} name="searchedAreaCode" type="text" id="searchedAreaCode" className="form-control" onChange={this.handleInputChange} placeholder="Search by Area Code" />
                        </div>
                        <div className="form-group mx-sm-3 mb-2">
                            <button className="btn btn-primary" id="button-number-search" type="button" onClick={this.search}>Search</button>
                        </div>
                    </div>
                    
                </div>)}

                {this.state.authMsg && (<BootstrapAlert msg={this.state.authMsg}/>)}

                {this.state.isNumberSelected && (
                <div>
                    <button className="btn btn-primary margin-top-15 margin-bottom-15 margin-left-15" type="button" data-toggle="modal" data-target="#purchase-confirm-modal">Purchase Number</button>
                </div>)}

                {this.state.isSearching && (<div className="row">
                    <div className="col-md-4">
                        <NumbersLoading/>
                    </div>
                </div>)}

                {this.state.resultNumbers && (<div className="row">
                    <div className="col-md-4">

                            <ul className="list-group">
                                {numbers}
                            </ul>
                            
                        </div>
                </div>)}

                <div className="modal fade" id="purchase-confirm-modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Purchase</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                            <p>Are you sure you want to purchase the phone number <NormalizedNumber number={this.state.selectedNumber}/></p>
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.reserve}>Purchase</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>);
        
        
        
    }
}

export default NumberSearch;