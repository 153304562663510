import React from 'react';
import '../css/index.css';
import axios from 'axios';
import $ from "jquery";
import Cookies from 'universal-cookie';
import * as constants from '../utils/constants.js';
import Pricing from './pricing'
import BootstrapAlert from './bootstrapAlert.jsx';
import { PayPalButton } from "react-paypal-button-v2";
import progressSpinner from '../img/rolling.gif';
import firebase from '../utils/firebase.js';
import publicIp from 'public-ip';

class Balance extends React.Component{
    
    constructor(props, context) {
        super(props, context);

        const cookies = new Cookies();
        
        var email = cookies.get(constants.COOKIE_EMAIL_KEY);
        if (!email) {
            window.location.href = '../login';
            return;
        }

        this.state = ({
            amount: '2.00',
            email: email + ',dlrs',
            tkn: '1' ,
            balance: '0.00',
            custId: '',
            isBalanceLoaded: false,
            isLoading: false,
            isPaymentProcessing: false,
            numberSelectDisabled: false,
            ip:''
            
        });

        this.goToSearchNumbers = this.goToSearchNumbers.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.sendPaymentInfo = this.sendPaymentInfo.bind(this);
    }

    async componentDidMount(){
        

        
       
        const cookies = new Cookies();
        var token = cookies.get('snrb_token');
        var custId = cookies.get('snrb_cust_id');
        var userId = cookies.get(constants.COOKIE_ID_KEY)
        var ipV4 = await publicIp.v4();
        var self = this;

        if (!token || !custId) {
            window.location.href = '../login';
            return;
        } else {
            cookies.set(constants.COOKIE_ID_KEY, custId, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });
            cookies.set(constants.COOKIE_TOKEN_KEY, token, { path: '/' , expires: constants.COOKIE_EXPIRE_TIME });       
        }

        var self = this;
        
        axios.get(constants.getBalanceUrl(custId, token))
            .then((response) => { 
                var data = response.data;
                var balance = data.Dollars;

                data.Dollars = data.Dollars.toFixed(2);
                data.Dollars = data.Dollars;
                this.setState({
                    balance: data.Dollars,
                    custId: custId,
                    isBalanceLoaded: true,
                    isLoading: false,
                    numberSelectDisabled: false,
                    ipv4: ipV4                
                });

                if (parseFloat(balance) > 0.0 && data.NumberCount === 0) {
                    $('#numbers-call-to-action').modal('show');
                }
            }).catch((error) => {
                
                var msg = {
                    description: error.message,
                    alertType: 'danger',
                    isDismissible: false
                }

                self.setState({
                    errorMsg: msg,
                    isBalanceLoaded: false,
                    isLoading: false,
                    numberSelectDisabled: false
                });
            });
    }

    sendPaymentInfo(amount, orderData, transactionId){
        
        var self = this;
        
        const cookies = new Cookies();
        
        debugger;
        
        axios({
            method: 'post',
            url: constants.PAYMENT_VERIFY_URL,
            headers: {
                'Authorization': cookies.get(constants.COOKIE_BEARER_TOKEN)
            },
            data : {
                customerId: self.state.custId,
                amount: amount,
                orderId: orderData.orderID,
                email: cookies.get(constants.COOKIE_EMAIL_KEY),
                ipAddress: self.state.ipv4,
                transactionId: transactionId
            }
        }).then(function(res) {
            if (res) {
                self.setState({
                    isPaymentProcessing: false,
                    balance: res.data.NewBalance                    
                });
                $('#payment-success-modal').modal('show');
            }
        }).catch(function (error) {
            if(error.response){
                
                var msg = {
                        description: 'Unable to process payment',
                        alertType: 'danger',
                        isDismissible: true
                    }

                    self.setState({
                        isPaymentProcessing: false,
                        paymentError : msg, showProgressbar: false
                    });
            }                
    
        });
                            
        
    }

    handleAmountChange(e) {
        var selectedAmount = e.target.value;
        this.setState({ amount: selectedAmount });
    }

    goToSearchNumbers() {
        window.location.href = constants.BASE_PORTAL_URL + 'number/search';
    }

    render(){

        let balence = '';

        if(this.state.isLoading){
            balence = (
                <div>
                    <div className="ph-item">
                        <div class="ph-row">
                            <div class="ph-col-4 empty"></div>
                            <div class="ph-col-4 empty"></div>
                            <div class="ph-col-4 empty"></div>
                            <div class="ph-col-12"></div>
                            <div class="ph-col-4 empty"></div>
                            <div class="ph-col-4 empty"></div>
                            <div class="ph-col-4 empty"></div>
                        </div>
                    </div>
                </div>
            )
        }else if(!this.state.isBalanceLoaded){
            balence = <BootstrapAlert msg={this.state.errorMsg }/>;
        }else {
            let displayBalance = (Math.round(this.state.balance * 100) / 100).toFixed(2);
            balence = <p className='credit-display-large'>${displayBalance}</p>;
        }

        var self = this;
        var purchaseDetails;

        return (
            <div className="row">
                <div className="col-md-4">
                    <h2>Account Balance</h2>
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                            <h3 className="card-title">Current Balance</h3>
                                {balence}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">Add Funds</h3>

                                <h5>Amount</h5>
                                <select id="pmt-values-drop-down" disabled = {(this.state.numberSelectDisabled)? "disabled" : ""} className="margin-bottom-15" value={this.state.amount} onChange={this.handleAmountChange }>
                                    <option value="2.00">$2.00</option>
                                    <option value="5.00">$5.00</option>
                                    <option value="10.00">$10.00</option>
                                    <option value="20.00">$20.00</option>
                                </select>

                                {!this.state.isLoading && this.state.isBalanceLoaded && (

                                     

                                    <div>
                                        <PayPalButton
                                            amount={this.state.amount}
                                            shippingPreference="NO_SHIPPING"
                                            onSuccess={(details, data) => {
                                                var transactionId = '';
                                                
                                                self.setState({
                                                    isPaymentProcessing: true,
                                                    paymentError: null
                                                });
                                                
                                                if(details && details.purchase_units[0]
                                                    && details.purchase_units[0].payments
                                                    && details.purchase_units[0].payments.captures
                                                    && details.purchase_units[0].payments.captures[0]
                                                    && details.purchase_units[0].payments.captures[0].id){
                                                        transactionId = details.purchase_units[0].payments.captures[0].id;
                                                }

                                                self.sendPaymentInfo(self.state.amount, data, transactionId);    
                                                
                                                firebase.analytics().logEvent('paypal_payment', {
                                                    customer_id: self.state.custId,
                                                    amount: self.state.amount
                                                });
                                            }}
                                            options={{
                                                //clientId: "Ae00A67b6egDL4ER1BhtlLZy6vlLUqELJRvt4l-Mu_GuJVigHVRU-gslYmHnbDSPASyK7StWh3mmPgkZ"
                                                clientId: "AStOQrFseJjIN5o_hEwoiRPPcaT3KrgHH3jXUut2ZLP1IgVOVHCPg5DMuSfk9fOdR4qxDO_TRSge40hj"
                                            }}
                                        />
                                       
                                    </div>    
                                )}

                                {this.state.isLoading && (
                                    <div>
                                        <div className="ph-item">
                                            <div class="ph-row">
                                                <div className="ph-col-12 big" />
                                                <div className="ph-col-12 big" />
                                            </div>
                                        </div>
                                        <div className="ph-item">
                                            <div class="ph-row">
                                                <div className="ph-col-12 big" />
                                                <div className="ph-col-12 big" />
                                            </div>
                                        </div>
                                        <div className="ph-item">
                                            <div class="ph-row">
                                                <div className="ph-col-12 big" />
                                                <div className="ph-col-12 big" />
                                            </div>
                                    </div>
                                </div>
                                )}
                                
                                {this.state.isPaymentProcessing && (
                                    <div>
                                        <p><strong>Finalizing Payment</strong> <img src={progressSpinner} height="15" width="15" alt="loading" /></p>    
                                    </div>
                                    )
                                }
                                {this.state.paymentError && (<BootstrapAlert msg={this.state.paymentError}/>)}
                                
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="numbers-call-to-action" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Get Started</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>    
                                </div>
                                <div className="modal-body">
                                    <h3 className="paragraph-header">Search for a Number to get Started!</h3>
                                    <p>
                                        Now that you have an account balance you can purchase a number.
                                        In order to get started you must search for a number.
                                        Click "Search Numbers" to get started now.
                                        </p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.goToSearchNumbers}>Search Numbers</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <Pricing/>
                </div>
                <div className="modal fade" id="payment-success-modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                   <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Purchase Complete!</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <h3 className="paragraph-header">Search for a Number to Continue!</h3>
                            <p>Your purchase has been processed and your account has been credited*.</p>
                            <p>
                                You can use your account balance to purchase a number.
                                In order to get started you must search for a number.
                                Click "Search Numbers" to get started now.
                            </p>

                            <p className="small"><strong>*Don't see your latest purchase?</strong> There may be a short deplay before the credit is reflected in your account</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.goToSearchNumbers}>Search Numbers</button>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        );
    }
}

export default Balance;