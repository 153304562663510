import * as firebase from 'firebase';
import 'firebase/analytics';
const settings = {timestampsInSnapshots: true};

var config = {
    apiKey: "AIzaSyApm7_yOiciahR_0t2Xq9lIx3BWmA3oqt4",
    authDomain: "snrb-portal.firebaseapp.com",
    databaseURL: "https://snrb-portal.firebaseio.com",
    projectId: "snrb-portal",
    storageBucket: "snrb-portal.appspot.com",
    messagingSenderId: "839892980886",
    appId: "1:839892980886:web:dcd28020704814fcd69eb7",
    measurementId: "G-X979D970TP"
  };

firebase.initializeApp(config);
firebase.analytics();

export default firebase;

